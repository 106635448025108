let apiVersion = process.env.REACT_APP_API_URL;
// let apiVersion="http://127.0.0.1:3001/tactopus-connect-staging/us-central1/api/";
let kpAPIVersion = process.env.REACT_APP_KP_API_URL;

//Authorization URL
export const getUserProfileUrl = `${apiVersion}v1/profile`;
export const getUserDataUrl = `${apiVersion}v1/user-data/`;
export const saveUserProfileUrl = `${apiVersion}v1/register/expert?step=`;
export const saveUserProfileImageUrl = `${apiVersion}v1/upload-image`;

export const getParentCustomTokenUrl = `${kpAPIVersion}v1/get_custom_token/`;

export const getPopularArticleUrl = `${kpAPIVersion}v1/popular_articles/`;

//Schedule Page URL
export const getTherapistSessionsUrl = `${apiVersion}v1/therapist-sessions`;

// MY students page Url
export const getAllStudentsUrl = `${apiVersion}v1/expert/mystudents`;
export const getStudentsPathwayUrl = `${apiVersion}v1/get-child-pathway?parentId=`;
export const getCreatePathwayDomainGoalsUrl = `${apiVersion}v1/get-domains-goals`;
export const getCreatePathwaySubGoalUrl = `${apiVersion}v1/get-subGoals-outcomes?goalId=`;
export const getParentSessionUrl = `${apiVersion}v1/get-parent-sessions?userId=`;
export const getParentJournalUrl = `${apiVersion}v1/get-parent-journal?`;
export const getDomainUrl = `${apiVersion}v1/get-child-domains?userId=`;
export const getStudentNotesUrl = `${apiVersion}v1/get-note?parentId=`;
export const writeStudentNotesUrl = `${apiVersion}v1/add-note`;
export const deleteStudentNotesUrl = `${apiVersion}v1/delete-note/`;
export const updateStudentNotesUrl = `${apiVersion}v1/update-note/`;
export const getChildDomainsUrl = `${apiVersion}v1/get-child-domains?userId=`;

// My profile Page Url
export const getExpertTransactions = `${apiVersion}v1/transaction-details?`;
export const getExpertLeaves = `${apiVersion}v1/expert/get-expert-leaves?status=`;
export const applyLeaves = `${apiVersion}v1/expert/apply-leaves`;
export const editUserProfile = `${apiVersion}v1/edit-user-profile`;
export const getMyExperts = `${apiVersion}v1/parent/get-child-experts`;
export const uploadChildDocs = `${apiVersion}v1/parent/child-doccuments`;
export const deleteDocument = `${apiVersion}v1/delete-document/`;
export const getExpertLeavesByIdUrl = `${apiVersion}v1/expert/get-expert-leaves-by-id?expertId=`;
export const uploadChildDocsSupportUrl = `${apiVersion}v1/support/upload-child-documents/`;

// Pathway URL
export const getChildPathwayUrl = `${apiVersion}v1/get-child-pathway`;
export const addParentOutcomeStatusUrl = `${apiVersion}v1/parent/add-journal-entry`;
export const createPathwayUrl = `${apiVersion}v1/create-pathway`;
export const startCheckpointUrl = `${apiVersion}v1/start-checkpoint/`;
export const changeOutcomeStatusUrl = `${apiVersion}v1/change-outcome-status/`;
export const completeCheckpointUrl = `${apiVersion}v1/complete-checkpoint/`;
export const downloadCheckpointUrl = `${apiVersion}v1/expert/checkpoint-report?id=`;

//Resources Url
export const getDomainResourcesUrl = `${apiVersion}v1/expert/get-domain-resources?domainId=`;
export const getFavouriteResourcesUrl = `${apiVersion}v1/expert/get-favourite-resources`;
export const addFavouriteResourcesUrl = `${apiVersion}v1/expert/add-favourite-resources/`;

//Sessions Url
export const getParentSessions = `${apiVersion}v1/parent/get-parent-filter-sessions?status=`;
export const getOpenSlots = `${apiVersion}v1/parent/get-available-slots`;
export const scheduleSessionUrl = `${apiVersion}v1/parent/schedule-sessions-parent`;
export const cancelSessionUrl = `${apiVersion}v1/parent/cancel-session`;
export const getRescheduleOpenSlotsUrl = `${apiVersion}v1/parent/get-reschedule-available-slots`;
export const rescheduleSessionUrl = `${apiVersion}v1/parent/reschedule-session`;
export const getLastAttendedSessionUrl = `${apiVersion}v1/get-last-attended-session?userId=`;
export const scheduleSessionExpertUrl = `${apiVersion}v1/schedule-session-expert`;
export const startMeetingUrl = `${apiVersion}v1/start-meeting`;
export const confirmSessionRequestUrl = `${apiVersion}v1/parent/confirm-session-request`;
export const rejectSessionRequestUrl = `${apiVersion}v1/parent/reject-session-request`;
export const getParentUpcomingSession = `${apiVersion}v1/parent/get-upcoming-sessions`;
export const joinConsulatationUrl = `${apiVersion}v1/join-consulatation?consultationId=`;
export const getBalanceDetailsUrl = `${apiVersion}v1/get-user-balance`;
export const blockSlotUrl = `${apiVersion}v1/block-slot`;
export const deleteblockSlotUrl = `${apiVersion}v1/delete-block-slot`;

export const getSupportSessionsUrl = `${apiVersion}v1/support/get-support-sessions`;
export const cancelSessionSupportUrl = `${apiVersion}v1/support/cancel-session`;
export const setOngoingSessionUrl = `${apiVersion}v1/support/set-ongoing-session/`;
export const setPartiallyAtttendedSessionUrl = `${apiVersion}v1/support/set-partially-attended-session/`;
export const getParentByExpertIdUrl = `${apiVersion}v1/support/get-expert-parents?expertId=`;
export const getAllExpertsUrl = `${apiVersion}v1/support/get-all-experts`;
export const scheduleSessionSupportUrl = `${apiVersion}v1/support/schedule-session`;
export const setNoShowConsultationUrl = `${apiVersion}v1/support/set-noshow-consultation/`;
export const setAtttendedConsultationUrl = `${apiVersion}v1/support/set-attended-consultation/`;
export const UpdateConsultationStatusUrl = `${apiVersion}v1/support/update-consultation-status/`;

//Feedback url
export const addFeedbackUrl = `${apiVersion}v1/add-feedback`;
export const getSessionByIdUrl = `${apiVersion}v1/unauthenticated/get-session-by-id?sessionId=`;

//Purchase session
export const getPurchasePlans = `${apiVersion}v1/parent/get-purchase-plans`;
export const createOrder = `${apiVersion}v1/parent/create-order`;
export const handlePaymentTransactions = `${apiVersion}v1/parent/handle-payment-transactions`;

//Explore
export const getActivityListingUrl = `${kpAPIVersion}v1/activitylisting/`;
export const getVideosUrl = `${kpAPIVersion}v1/play_video/`;
export const getGuidesDataUrl = `${kpAPIVersion}v1/learn_course/`;
export const getParentingTipsUrl = `${kpAPIVersion}v1/parenting_tip/`;
export const getLiveBookingUrl = `${kpAPIVersion}v1/booking/`;

//update ACR
export const getConsultationByIdUrl = `${apiVersion}v1/consultation-details?consulatationId=`;
export const updateAcrUrl = `${apiVersion}v1/expert/update_acr?step=`;
export const getConsultationReportUrl = `${apiVersion}v1/expert/consultation-report?consultationId=`;

//support parent
export const getAllParentsUrl = `${apiVersion}v1/support/get-all-parents`;
export const getParentExperts = `${apiVersion}v1/support/get-child-experts?parentId=`;
