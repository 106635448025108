import AuthenticatedContainer from "../../../container/Authenticated";

const SupportExperts = () => {
  return (
    <AuthenticatedContainer>
      <div>Support Experts Page</div>
    </AuthenticatedContainer>
  );
};

export default SupportExperts;
