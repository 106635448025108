import React from "react";
import { Box } from "@chakra-ui/layout";
import { Flex, Heading } from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";
import PopUpDetails from "./Steps/popupDetails";
import NoShowDetails from "./Steps/NoShowDetails";
import NoShowPerson from "./Steps/noShowPerson";

export const ReportNoShowStepper = ({
  session,
  activeStep,
  noShowBy,
  setNoShowBy,
  expertWait,
  setExpertWait,
}) => {
  const steps = [
    {
      element: (
        <NoShowPerson
          title="Which party did not show up for the consultation?"
          noShowBy={noShowBy}
          setNoShowBy={setNoShowBy}
        />
      ),
    },
    ...(noShowBy === "Parent"
      ? [
          {
            element: (
              <PopUpDetails
                expertWait={expertWait}
                setExpertWait={setExpertWait}
              />
            ),
          },
        ]
      : []),

    {
      element: (
        <NoShowDetails
          session={session}
          noShowBy={noShowBy}
          expertWait={expertWait}
        />
      ),
    },
  ];

  return (
    <Flex flexDir="column" width="100%" backgroundColor="white" pt="16px">
      <Steps
        variant="circles-alt"
        orientation="horizontal"
        responsive={false}
        colorScheme="blue"
        sx={{
          "& .css-eb100c": {
            _completed: {
              color: "white",
            },
            color: "unset",
          },
          "& .css-1emvh1t, .css-vv8cpc": {
            fontSize: "14px",
            fontFamily: "Inter",
            mt: "8px",
            lineHeight: "20px",
          },
          "& .css-yli7i8:not(:last-child):after": {
            top: "calc(45px/2)",
            // borderBottom: 'dashed',
          },
          "& .css-1j537u4[aria-current=step]": {
            backgroundColor: "#4299E1",
            color: "white",
            border: "none",
            textColor: "white",
          },
          "& .css-1j537u4": {
            height: "32px",
            width: "32px",
          },
        }}
        activeStep={activeStep}
      >
        {steps.map(({ label, element }, index) => (
          <Step label={label} key={index}>
            <Box sx={{ p: "4px" }}>
              <Heading
                sx={{
                  "& .chakra-form__required-indicator": {
                    color: "black",
                  },
                }}
                textAlign="center"
              >
                {element}
              </Heading>
            </Box>
          </Step>
        ))}
      </Steps>
    </Flex>
  );
};
