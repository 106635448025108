import { useState } from "react";
import {
  Grid,
  GridItem,
  Image,
  Text,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  getWeekNo,
  getFullMonthName,
  getCurrentYear,
} from "../../utils/customMoment";
import {
  BACK_CARET,
  NEXT_CARET,
  ACTIVE_CALENDER_BLANK,
  sessionStatus,
  PLUS_ICON,
} from "../../constant";
import SessionsGoToPopup from "../SessionsGoToPopup";
import ScheduleSessionSupport from "../../pages/Support/ScheduleSession";

const ScheduleCalenderHeader = ({
  statusChange,
  status,
  startDate,
  handleNextWeekClick,
  handlePrevWeekClick,
  calenderStartDate,
  handleCalendarChange,
  handleGoNow,
  role,
  reqData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scheduleSessionOpen, setscheduleSessionOpen] = useState(false);

  const styles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      outline: "1px solid #A6B8C4",
      borderRadius: "10px",
      boxShadow: "none",
      maxWidth: "190px",
      width: "190px",
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
      maxWidth: "190px",
    }),
  };
  return (
    <Grid templateColumns="repeat(12, 1fr)" className="calenderTopBox" gap={4}>
      {scheduleSessionOpen && (
        <ScheduleSessionSupport
          setOverlayOpen={setscheduleSessionOpen}
          reqDataSupport={reqData}
        />
      )}
      <SessionsGoToPopup
        isOpen={isOpen}
        onClose={onClose}
        calenderStartDate={calenderStartDate}
        handleCalendarChange={handleCalendarChange}
        handleGoNow={handleGoNow}
      />
      <GridItem
        colSpan={{ md: 3, lg: 2, xl: 2 }}
        display="flex"
        alignItems="center"
      >
        <Select
          useBasicStyles
          size="sm"
          options={sessionStatus}
          chakraStyles={styles}
          selectedOptionStyle="#F3F8FB"
          onChange={statusChange}
          value={status}
          defaultValue={status}
        ></Select>
      </GridItem>
      <GridItem colSpan={5} display="flex" alignItems="center" ml="24px">
        <Image
          src={BACK_CARET}
          alt="back"
          onClick={handlePrevWeekClick}
          cursor="pointer"
          marginRight="24px"
        />
        <Image
          src={NEXT_CARET}
          alt="next"
          onClick={handleNextWeekClick}
          cursor="pointer"
        />
        <Text className="monthName">
          {getFullMonthName(startDate) + " " + getCurrentYear(startDate)}
        </Text>
        <Stack className="weekText">
          <Text>Week {getWeekNo(startDate)}</Text>
        </Stack>
      </GridItem>
      <GridItem
        colStart={9}
        colEnd={13}
        display="flex"
        justifyContent="end"
        gap={4}
      >
        <Stack className="goToDiv" onClick={onOpen}>
          <Text>Go To</Text>
          <Image src={ACTIVE_CALENDER_BLANK} className="calenderImg" />
        </Stack>
        {(role === "support" ||
          role === "sales-admin" ||
          role === "program") && (
          <Stack
            className="newSessionButton"
            onClick={() => setscheduleSessionOpen(true)}
          >
            <Text>New Session</Text>
            <Image src={PLUS_ICON} className="calenderImg" />
          </Stack>
        )}
      </GridItem>
    </Grid>
  );
};
export default ScheduleCalenderHeader;
