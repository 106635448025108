import { takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as userMiddleWare from "./userSagas";
import * as scheduleMiddleWare from "./scheduleSagas";
import * as pathwayMiddleWare from "./pathway";
import * as purchaseMiddleWare from "./purchaseSagas";
import * as resourceMiddleware from "./resources";

export default function* mySaga() {
  yield takeLatest(
    actionTypes.GET_USER_PROFILE,
    userMiddleWare.getUserProfileSagaCall
  );
  yield takeLatest(
    actionTypes.GET_THERAPIST_SESSIONS,
    scheduleMiddleWare.getTherapistSessionsSagaCall
  );
  yield takeLatest(
    actionTypes.SAVE_USER_PROFILE_IMAGE_DETAILS,
    userMiddleWare.saveUserProfileImageSagaCall
  );
  yield takeLatest(
    actionTypes.SAVE_USER_PROFILE_DETAILS,
    userMiddleWare.saveUserProfileDetailsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_EXPERT_TRANSACTIONS,
    userMiddleWare.getExpertTransactionsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_EXPERT_LEAVES,
    userMiddleWare.getExpertLeavesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_TOKEN,
    userMiddleWare.getParentTokenSagaCall
  );
  yield takeLatest(
    actionTypes.GET_CHILD_PATHWAY,
    pathwayMiddleWare.getChildPathwaySagaCall
  );
  yield takeLatest(
    actionTypes.GET_ALL_STUDENTS,
    userMiddleWare.getAllStudentsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_DOMAINS_GOAL_DATA,
    userMiddleWare.getStudentsDomainGoalsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_DOMAIN_SUB_GOALS_DATA,
    userMiddleWare.getStudentsSubGoalDomainSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_SESSION_HISTORY,
    userMiddleWare.getParentSessionHistorySagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_JOURNAL,
    userMiddleWare.getParentJournalSagaCall
  );
  yield takeLatest(
    actionTypes.GET_STUDENTS_NOTES,
    userMiddleWare.getStudentNotesSagaCall
  );
  yield takeLatest(
    actionTypes.WRITE_STUDENTS_NOTES,
    userMiddleWare.writeStudentNotesSagaCall
  );
  yield takeLatest(
    actionTypes.DELETE_STUDENTS_NOTES,
    userMiddleWare.deleteStudentNotesSagaCall
  );
  yield takeLatest(
    actionTypes.UPDATE_STUDENTS_NOTES,
    userMiddleWare.updateStudentsNotesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_CHILDS_DOMAIN_DATA,
    userMiddleWare.getChildsDomainSagaCall
  );

  yield takeLatest(
    actionTypes.ADD_PARENT_OUTCOME_STATUS,
    pathwayMiddleWare.addParentOutcomeStatusSagaCall
  );
  yield takeLatest(
    actionTypes.APPLY_EXPERT_LEAVES,
    userMiddleWare.applyExpertLeavesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_DOMAIN_RESOURCES,
    pathwayMiddleWare.getDomainResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_FAVOURITE_RESOURCES,
    pathwayMiddleWare.getFavouriteResourcesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_MY_EXPERTS,
    userMiddleWare.getChildsExpertsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_SESSIONS,
    scheduleMiddleWare.getParentSessionsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_OPEN_SLOTS,
    scheduleMiddleWare.getOpenSlotsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_RESCHEDULE_OPEN_SLOTS,
    scheduleMiddleWare.getRescheduleOpenSlotsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_USER_DATA,
    userMiddleWare.getUserDataSagaCall
  );
  yield takeLatest(
    actionTypes.GET_LAST_ATTENDED_SESSION,
    scheduleMiddleWare.getLastAttendedSessionSagaCall
  );
  yield takeLatest(
    actionTypes.GET_SESSION_BY_ID,
    scheduleMiddleWare.getSessionByIdSagaCall
  );
  yield takeLatest(
    actionTypes.SAVE_START_MEETING,
    scheduleMiddleWare.startMeetingSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PURCHASE_PLANS,
    purchaseMiddleWare.getPurchasePlansSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_UPCOMING_SESSION,
    scheduleMiddleWare.getParentUpcomingSessionSagaCall
  );
  yield takeLatest(
    actionTypes.GET_ACTIVITY_LISTING,
    resourceMiddleware.getActivityListingSagaCall
  );
  yield takeLatest(
    actionTypes.GET_VIDEO_LISTING,
    resourceMiddleware.getVideosListingSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENTING_TIPS,
    resourceMiddleware.getParentingTipsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_LIVE_BOOKING,
    resourceMiddleware.getLiveBookingSagaCall
  );
  yield takeLatest(
    actionTypes.GET_JOIN_CONSULATATION,
    scheduleMiddleWare.joinConsulatationSagaCall
  );
  yield takeLatest(
    actionTypes.GET_POPULAR_ARTICLE,
    userMiddleWare.getPopularArticlesSagaCall
  );
  yield takeLatest(
    actionTypes.GET_BALANCE_DETAILS,
    userMiddleWare.getBalanceDetailsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_CONSULTATION_BY_ID,
    scheduleMiddleWare.getConsultationByIdSagaCall
  );
  yield takeLatest(
    actionTypes.GET_CONSULTATION_REPORT,
    scheduleMiddleWare.getConsultationReportSagaCall
  );
  yield takeLatest(
    actionTypes.DOWNLOAD_CHECKPOINT,
    pathwayMiddleWare.downloadCheckpointSagaCall
  );
  yield takeLatest(
    actionTypes.GET_SUPPORT_SESSIONS,
    scheduleMiddleWare.getSupportSessionsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_ALL_EXPERTS,
    scheduleMiddleWare.getAllExpertsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_EXPERTS_PARENTS,
    scheduleMiddleWare.getParentsByExpertIdSagaCall
  );
  yield takeLatest(
    actionTypes.GET_EXPERTS_LEAVES_BY_ID,
    userMiddleWare.getExpertLeavesByIdSagaCall
  );
  yield takeLatest(
    actionTypes.GET_ALL_PARENTS,
    userMiddleWare.getAllParentsSagaCall
  );
  yield takeLatest(
    actionTypes.GET_PARENT_EXPERTS,
    userMiddleWare.getParentExpertsSagaCall
  );
}
