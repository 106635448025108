import { ModalBody, ModalHeader, Text, Flex } from "@chakra-ui/react";
import { getDatewithDay } from "../../../utils/customMoment";

const PopUpDetails = ({ session, type, cancelledBy }) => {
  return (
    <>
      <ModalHeader className="popupHeader">
        You are about to
        {type === "Cancel" ? type : " request a reschedule for"} the following
        consultation:
      </ModalHeader>
      <ModalBody className="popupBody">
        <Text className="sessionDetailsDate" mb="20px">
          {session.sessionParentName + "-" + session.sessionStudentName} /{" "}
          {session.sessionExpertName}
        </Text>

        <Text className="sessionDetailsDate" mb="20px">
          {session.serviceType}
        </Text>

        <Flex mb="10px">
          <Text className="sessionDetailsDate">
            {getDatewithDay(session?.sessionDate)}
          </Text>
          <Text style={{ margin: "-5px 8px" }}>.</Text>
          <Text className="sessionDetailsDate">
            {session?.startTime + " - " + session?.endTime}
          </Text>
        </Flex>

        <Flex mb="10px">
          <Text className="sessionDetailsDate" color="#BA1A1A">
            New Status:{" "}
            {type === "Cancel"
              ? "Cancelled By " + cancelledBy + "(Not charged)"
              : "Reschedule requested By " + cancelledBy}
          </Text>
        </Flex>
      </ModalBody>
    </>
  );
};
export default PopUpDetails;
