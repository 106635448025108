import React, { useEffect, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import { useLocation } from "react-router-dom";
import AuthenticatedContainer from "../../../container/Authenticated";
import {
  Avatar,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Show,
} from "@chakra-ui/react";
import {
  ACTIVE_SORTING_ICON,
  OPEN_PATHWAY,
  INACTIVE_SORTING_ICON,
} from "../../../constant";
import "./myStudents.css";
import GoToTop from "./goToTop";
import "./myStudents.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentsDataAction,
  toggleAPILoader,
  getChildPathway,
} from "../../../modules/actions";
import { getStudentsPathwayService } from "../../../modules/services";
import PathwayHandler from "./utils/pathwayHandler";
import CreatePathwayHandler from "./utils/createPathwayHandler";
import Loader from "../../../common/components/Loader";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";

const MyStudents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showingTable, setShowingTable] = useState(true);
  const [selectedChild, setSelectedChild] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [pathwayExists, setPathwayExists] = useState(false);
  const [willCreatePathway, setWillCreatePathway] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const userState = useSelector((state) => state?.user);
  const loaderState = useSelector((state) => state?.loader?.apiLoader);
  const pathway = useSelector((state) => state?.pathway[0]);

  useEffect(() => {
    if (location.state) {
      setInitialLoad(true);
      setShowingTable(false);
      setSelectedChild(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (loaderState === false) {
      setInitialLoad(false);
    }
  }, [loaderState]);

  useEffect(() => {
    dispatch(getStudentsDataAction());
  }, []);

  useEffect(() => {
    if (selectedChild !== null) {
      dispatch(toggleAPILoader(true));
      dispatch(getChildPathway(selectedChild.id));
    }
  }, [selectedChild]);

  const dateFormatter = (dateTime) => {
    const date = String(new Date(dateTime)).split(" ");
    return `${date[2]} ${date[1]}, ${date[3]}`;
  };

  useEffect(() => {
    const students = userState?.students;
    const duplicate = students?.map((item) => {
      return {
        ...item,
        lastScheduled: item?.lastScheduled
          ? dateFormatter(item?.lastScheduled)
          : "-",
      };
    });
    setRowData(duplicate || []);
  }, [userState]);

  const handlePathwayClick = async (id) => {
    const studentsData = userState?.students;
    const singleStudent = studentsData?.filter((child) => child.id === id);
    setSelectedChild(singleStudent[0]);
    setInitialLoad(true);
  };

  useEffect(() => {
    const getChildsPathway = async () => {
      try {
        if (selectedChild === null) return;
        const res = await getStudentsPathwayService(selectedChild?.id);
        const { data } = res.data;
        data.length ? setPathwayExists(true) : setPathwayExists(false);
        setInitialLoad(false);
        setShowingTable(false);
      } catch (error) {
        console.log(error);
      }
    };
    getChildsPathway();
  }, [selectedChild]);

  const data = useMemo(() => [...rowData], [rowData]);
  const columns = useMemo(
    () => [
      {
        Header: "Last Schedule",
        accessor: "lastScheduled", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "date",
      },
      {
        Header: "Activity Status",
        accessor: "activityStatus",
        id: "activityStatus",
        disableSortBy: true,
      },
      {
        Header: "Child Name",
        accessor: "childName", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "alphanumeric",
      },
      {
        Header: "Age",
        accessor: "age", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "alphanumeric",
      },
      {
        Header: "Parent Name",
        accessor: "fullName", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "alphanumeric",
      },
      {
        Header: "id",
        accessor: "id",
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "View",
        Header: "View",
        Cell: ({ row }) => (
          <Image
            cursor="pointer"
            onClick={() => handlePathwayClick(row.values.id)}
            src={OPEN_PATHWAY}
          />
        ),
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultCanSort: true, // Enable sorting by default
        sortTypes: {
          alphanumeric: (rowA, rowB, columnId) => {
            const valueA = rowA.values[columnId];
            const valueB = rowB.values[columnId];
            // Case-sensitive string comparison
            return valueA?.localeCompare(valueB, undefined, {
              sensitivity: "base",
            });
          },
          date: (rowA, rowB, columnId) => {
            const valueA = new Date(rowA.values[columnId]);
            const valueB = new Date(rowB.values[columnId]);
            return valueA - valueB;
          },
        },
      },
      tableHooks,
      useSortBy
    );

  return (
    <AuthenticatedContainer>
      <MobileNotAllowedScreen />
      <Show breakpoint="(min-width:767px)">
        {initialLoad ? (
          <Loader text={"Loading Students...."} />
        ) : showingTable ? (
          <Grid
            maxW="100vw"
            row
            className="layoutBanner"
            px={{ base: "50px", lg: "71px" }}
            py="24px"
          >
            <GridItem className="mainScheduleBox">
              <Table border="1px solid #d8e5ee" {...getTableProps()}>
                <Thead>
                  {headerGroups?.map((hg) => (
                    <Tr {...hg.getHeaderGroupProps()}>
                      {hg.headers?.map((column) => (
                        <Th
                          className="tableHeader"
                          textTransform=""
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <Flex gap="8px" align="center">
                            {column.id !== "id" && column.render("Header")}
                            {column.id !== "View" &&
                            column.id !== "id" &&
                            column.id !== "activityStatus" ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <Image
                                    style={{ transform: "rotate(0.5turn)" }}
                                    src={ACTIVE_SORTING_ICON}
                                  />
                                ) : (
                                  <Image src={ACTIVE_SORTING_ICON} />
                                )
                              ) : (
                                <Image src={INACTIVE_SORTING_ICON} />
                              )
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {rows?.map((r) => {
                    prepareRow(r);
                    return (
                      <Tr {...r.getRowProps()}>
                        {r.cells?.map((c) => (
                          <Td className="tableData" {...c.getCellProps()}>
                            {c.column.id !== "childName" && c.column.id !== "id"
                              ? c.render("Cell")
                              : c.column.id === "childName" && (
                                  <Flex gap="8px" align="center">
                                    <Avatar
                                      size="sm"
                                      className="tableStudentAvatar"
                                      name={c.value}
                                      src={c.row.original.image}
                                    />
                                    <Text>{c.value}</Text>
                                  </Flex>
                                )}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </GridItem>
          </Grid>
        ) : !willCreatePathway ? (
          <PathwayHandler
            setPathwayExists={setPathwayExists}
            setShowingTable={setShowingTable}
            pathwayExists={pathwayExists}
            selectedChild={selectedChild}
            setWillCreatePathway={setWillCreatePathway}
            pathway={pathway}
          />
        ) : (
          <CreatePathwayHandler
            setPathwayExists={setPathwayExists}
            selectedChild={selectedChild}
            setWillCreatePathway={setWillCreatePathway}
            setShowingTable={setShowingTable}
            pathway={pathway}
          />
        )}
      </Show>
    </AuthenticatedContainer>
  );
};

export default MyStudents;
