// Combine all reducers and export root reducers
import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";
import loader, { modalOpen } from "./loader";
import user, {
  childExperts,
  token,
  transactions,
  userData,
  popularArticles,
  userBalance,
  expertLeavesById,
  allParents,
  parentExperts,
} from "./user";
import therapistSessions, {
  parentSessions,
  openSlots,
  rescheduleOpenSlots,
  lastAttentedSession,
  sessionById,
  meetingDetails,
  upcomingSession,
  consultationById,
  consultationReport,
  supportSessions,
  allExperts,
  expertsParents,
} from "./sessions";
import pathway, {
  favouriteResources,
  outcomes,
  resources,
  checkpointDocument,
} from "./pathway";
import { plansData } from "./purchase";
import {
  activityListing,
  videosListing,
  parentingTips,
  liveBooking,
} from "./resources";

const appReducer = combineReducers({
  user: user,
  userData: userData,
  loader: loader,
  modalOpen: modalOpen,
  therapistSessions: therapistSessions,
  token: token,
  pathway: pathway,
  outcomes: outcomes,
  resources: resources,
  favouriteResources: favouriteResources,
  childExperts: childExperts,
  transactions: transactions,
  parentSessions: parentSessions,
  openSlots: openSlots,
  rescheduleOpenSlots: rescheduleOpenSlots,
  lastAttentedSession: lastAttentedSession,
  sessionById: sessionById,
  meetingDetails: meetingDetails,
  plansData: plansData,
  upcomingSession: upcomingSession,
  activityListing: activityListing,
  videosListing: videosListing,
  parentingTips: parentingTips,
  liveBooking: liveBooking,
  popularArticles: popularArticles,
  userBalance: userBalance,
  consultationById: consultationById,
  consultationReport: consultationReport,
  checkpointDocument: checkpointDocument,
  supportSessions: supportSessions,
  allExperts: allExperts,
  expertsParents: expertsParents,
  expertLeavesById: expertLeavesById,
  allParents: allParents,
  parentExperts: parentExperts,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default rootReducer;
