import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, GridItem } from "@chakra-ui/react";
import moment from "moment";
import AuthenticatedContainer from "../../../container/Authenticated";
import ScheduleHeader from "../../../components/ScheduleHeader";
import ScheduleCalenderHeader from "../../../components/ScheduleCalenderHeader";
import ScheduleCalenderBody from "../../../components/ScheduleCalenderBody";
import SessionOverlay from "../../Expert/SessionOverlay";
import Loader from "../../../common/components/Loader";
import {
  getNextWeekDate,
  getWeekEndDate,
  getWeekStartDate,
  getPreviousWeekDate,
  getWeekStartDateForDate,
  getWeekEndDateForDate,
} from "../../../utils/customMoment";
import {
  getSupportSessions,
  toggleSessionLoader,
} from "../../../modules/actions";

const SupportSchedule = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.profile);
  const therapistSessions = useSelector((state) => state?.supportSessions);
  const sessionLoader = useSelector((state) => state?.loader?.sessionLoader);

  const [status, setSelectedOption] = useState({ value: "", label: "All" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState("");
  const [calenderStartDate, setCalenderStartDate] = useState();

  useEffect(() => {
    setStartDate(getWeekStartDate());
    setEndDate(getWeekEndDate());
  }, []);

  useEffect(() => {
    if (startDate && endDate) handleSessionsApiCall();
  }, [status, startDate, endDate]);

  const handleCalendarChange = (event) => {
    const selectedDate = moment(event);
    setCalenderStartDate(selectedDate);
  };

  const handleGoNow = (type) => {
    if (type === "Today") {
      setStartDate(getWeekStartDate());
      setEndDate(getWeekEndDate());
    } else {
      setStartDate(getWeekStartDateForDate(calenderStartDate));
      setEndDate(getWeekEndDateForDate(calenderStartDate));
    }
  };

  const handleNextWeekClick = () => {
    setStartDate(getNextWeekDate(startDate));
    setEndDate(getNextWeekDate(endDate));
  };
  const handlePrevWeekClick = () => {
    setStartDate(getPreviousWeekDate(startDate));
    setEndDate(getPreviousWeekDate(endDate));
  };

  const handleSessionsApiCall = () => {
    const reqData = {
      startDate: startDate,
      endDate: endDate,
      status: status.value,
    };

    dispatch(toggleSessionLoader(true));
    dispatch(getSupportSessions(reqData));
  };

  return (
    <AuthenticatedContainer>
      {overlayOpen && (
        <SessionOverlay
          setOverlayOpen={setOverlayOpen}
          selectedSession={selectedSession}
          therapistSessions={therapistSessions}
          setSelectedSession={setSelectedSession}
          reqData={{
            startDate: startDate,
            endDate: endDate,
            status: status.value,
          }}
          type="Support"
        />
      )}
      <Grid row className="layoutBanner" px="71px" py="24px">
        <GridItem className="mainScheduleBox">
          <ScheduleHeader
            countData={therapistSessions?.currentMonthCount}
            expertName={user?.fullName}
            loader={sessionLoader}
          />
          <ScheduleCalenderHeader
            statusChange={setSelectedOption}
            status={status}
            startDate={startDate}
            handleNextWeekClick={handleNextWeekClick}
            handlePrevWeekClick={handlePrevWeekClick}
            calenderStartDate={calenderStartDate}
            handleCalendarChange={handleCalendarChange}
            handleGoNow={handleGoNow}
            role={user?.role}
            reqData={{
              startDate: startDate,
              endDate: endDate,
              status: status.value,
            }}
          />
          {sessionLoader ? (
            <Loader text="“Take regular breaks between sessions”" />
          ) : (
            <ScheduleCalenderBody
              sessionsData={therapistSessions?.sessions}
              setOverlayOpen={setOverlayOpen}
              setSelectedSession={setSelectedSession}
              selectedSession={selectedSession}
            />
          )}
        </GridItem>
      </Grid>
    </AuthenticatedContainer>
  );
};

export default SupportSchedule;
