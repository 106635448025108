import { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "chakra-ui-steps";
import moment from "moment";
import { CancelSessionStepper } from "./cancelSessionStepper";
import {
  cancelSessionService,
  cancelSessionSupportService,
} from "../../modules/services";
import {
  toggleAPILoader,
  getParentSessions,
  toggleModalOpen,
  toggleSessionLoader,
  getTherapistSessions,
  toggleMainLoader,
  getParentUpcomingSession,
  getBalanceDetails,
  getSupportSessions,
} from "../../modules/actions";
import { Toaster } from "../../common/components/Toaster";
import "./cancellationPopup.css";

const CancellationPopup = ({
  isOpen,
  onClose,
  title,
  session,
  cancellingPerson,
  type,
  reqDataThearapistSessions,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.profile);
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const [data, setData] = useState({
    reason: "",
    reasonError: "",
    additionalDetails: "",
    additionalDetailsError: "",
  });
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [cancelledBy, setCancelledBy] = useState("Admin");
  const [message, setMessage] = useState("");
  const [penalty, setPenalty] = useState(0);
  const cancelTimeLimit = 240;
  const cancelTimeLimitExpert = 720;

  useEffect(() => {
    if (session) {
      timeComparison();
    }
  }, [session]);

  const timeComparison = () => {
    const now = new Date();
    const sessionDate = new Date(
      session?.sessionDate._seconds * 1000 +
        session?.sessionDate._nanoseconds / 1000000
    );

    // starting time of the session
    let startTime = session?.startTime.split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);
    startTime = startingHour * 60 + startingMinute;

    // This will give the difference in dates basically
    let timeDifference = sessionDate - now;
    timeDifference /= 1000 * 60;

    // This will give the difference between session start time and cancellation time in minutes
    timeDifference += startTime;

    if (cancellingPerson === "Parent" || cancelledBy === "Parent") {
      if (
        timeDifference > cancelTimeLimit ||
        session?.status === "Session Request"
      ) {
        setMessage("no penalty will be charged");
        setPenalty(0);
      } else if (timeDifference < cancelTimeLimit && timeDifference > 0) {
        setMessage("you will be charged 50% of the session fees AS FINE.");
        setPenalty((session?.sessionValue / 100) * 50);
      } else if (timeDifference < 0) {
        setPenalty(session?.sessionValue);
        setMessage("you will be charged 100% of the session fees AS FINE.");
      }
    } else if (cancellingPerson === "Expert" || cancelledBy === "Expert") {
      if (
        timeDifference > cancelTimeLimitExpert ||
        session?.status === "Session Request"
      ) {
        setPenalty(0);
        setMessage("no Penalty will be charged");
      } else if (timeDifference < cancelTimeLimitExpert && timeDifference > 0) {
        setPenalty((session?.sessionValue / 100) * 50);
        setMessage("you will be charged 50% of the session fees AS FINE.");
      } else if (timeDifference < 0) {
        setPenalty(session?.sessionValue);
        setMessage("you will be charged 100% of the session fees AS FINE.");
      }
    } else if (cancellingPerson === "Support" && cancelledBy === "Admin") {
      setPenalty(0);
      setMessage("no Penalty will be charged");
    }
  };
  const handleFormDataChange = (name, value, errorName) => {
    setBtnDisabled(false);
    setData({ ...data, [name]: value, [errorName]: "" });
  };
  const handleNextStep = () => {
    const { reason, additionalDetails } = data;

    if (
      activeStep === 0 ||
      (cancellingPerson === "Support" && activeStep === 1)
    ) {
      nextStep();
    } else {
      const validateReason = !reason ? "Please choose reason" : "";

      setData({
        ...data,
        reasonError: validateReason,
      });

      if (!validateReason) {
        const reqData = {
          reason: reason.value,
          additionalDetails,
          sessionId: session.id,
          penalty,
        };
        dispatch(toggleAPILoader(true));
        if (cancellingPerson === "Support") {
          reqData["cancelledBy"] = "Admin";
          reqData["cancelledByAdmin"] = user.fullName;
          cancelSessionSupportService(reqData)
            .then((res) => {
              Toaster("Session cancelled", "success", "top-left");
              dispatch(toggleAPILoader(false));
              dispatch(toggleModalOpen(false));

              dispatch(toggleSessionLoader(true));
              dispatch(getSupportSessions(reqDataThearapistSessions));

              handleClosePopup();
            })
            .catch((error) => {
              console.log(error);
              dispatch(toggleAPILoader(false));
              Toaster(error.response.data.message, "error", "top-left");
            });
        } else {
          cancelSessionService(reqData)
            .then((res) => {
              Toaster("Session cancelled", "success", "top-left");
              dispatch(toggleAPILoader(false));
              dispatch(toggleModalOpen(false));
              if (cancellingPerson === "Parent") {
                dispatch(
                  getParentSessions(
                    { status: "upcoming" },
                    {
                      filterDate: moment().format("YYYY/MM/DD"),
                    }
                  )
                );
                dispatch(toggleMainLoader(true));
                dispatch(
                  getParentUpcomingSession({
                    filterDate: moment().format("YYYY/MM/DD"),
                  })
                );

                dispatch(toggleSessionLoader(true));
                dispatch(getBalanceDetails());
              } else if (cancellingPerson === "Expert") {
                dispatch(toggleSessionLoader(true));
                dispatch(getTherapistSessions(reqDataThearapistSessions));
              }

              handleClosePopup();
            })
            .catch((error) => {
              console.log(error);
              dispatch(toggleAPILoader(false));
              Toaster(error.response.data.message, "error", "top-left");
            });
        }
      }
    }
  };
  const handleClosePopup = () => {
    dispatch(toggleModalOpen(false));
    setStep(0);
    setData({
      reason: "",
      reasonError: "",
      additionalDetails: "",
      additionalDetailsError: "",
    });
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        <CancelSessionStepper
          title={title}
          activeStep={activeStep}
          data={data}
          handleFormDataChange={handleFormDataChange}
          message={message}
          session={session}
          type={type}
          cancellingPerson={cancellingPerson}
          cancelledBy={cancelledBy}
          setCancelledBy={setCancelledBy}
        />

        <ModalFooter
          mt="16px"
          borderTop="1px solid #a6b8c4"
          justifyContent="space-between"
        >
          <Text
            className="readPolicyText"
            onClick={() =>
              window.open(
                cancellingPerson === "Parent"
                  ? "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Session+Cancellations+Policy+by+Client+(1).pdf"
                  : "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Session+Cancellations+Policy+by+Expert+(1).pdf"
              )
            }
          >
            Read policy
          </Text>

          <Flex>
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={() => {
                handleClosePopup();
              }}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={handleNextStep}
              isDisabled={
                (cancellingPerson !== "Support" &&
                  activeStep === 1 &&
                  btnDisabled) ||
                (cancellingPerson === "Support" &&
                  activeStep === 2 &&
                  btnDisabled)
              }
            >
              {apiLoader ? (
                <Spinner />
              ) : activeStep === 0 ||
                (cancellingPerson === "Support" && activeStep === 1) ? (
                "Next"
              ) : (
                "Confirm"
              )}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancellationPopup;
