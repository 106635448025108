export const REGEX = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // PASSWORD: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,

  // CONTACT: /^\d{10}$/,
  CONTACT1: /^(\+[0-9]{1,5})?(\\-)?([0-9]{10,15})$/,

  CONTACT: /^((\+[0-9]{1,3})?([0-9 -])){8,15}$/m,
  // /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
  NAME: /^(?!\s)(?![\s\S]*\s$)[A-Za-z0-9 ]+$/,
  FAX: /\+[0-9]{1,3}\([0-9]{3}\)[0-9]{7}$/,
  // /^\+?[0-9]{6,}$/,
  USERNAME: /^\S*$/,
  ///[\p{L}\p{M}\p{S}\p{N}\p{P}]+/
  NOT_BLANK_SPACE: /^(?!\s)(?![\s\S]*\s$)/,
  ADDRESS: /([a-zA-z0-9\\''(),\s-]{2,250})$/,
  ONLY_NO: /\D/,
  ONLY_CHAR: /@!#\$%\^&*()_-\.\?/,
  ///^[0-9a-zA-Z\_-]+$/
  COMMA_AND_ALPHABET: /[a-zA-Z\\s]\\,[a-zA-Z\\s]/,

  CERTIFICATE_NUMBER: /^(?=.*[0-9])([a-zA-Z0-9]+)$/, //only letters and number with atleast 1 number
  CHARACTER_DASH_NUMBER: /[a-zA-Z]+[-]+[0-9]+$/,
  NUMBER_DASH_CHARACTER: /[0-9]+[-]+[a-zA-Z]+$/,
  ONLY_NUMBER: /^\d+$/,
  ONLY_CHARACTER: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
  ONLY_PICTURES: /(\.jpg|\.jpeg|\.png)$/i,
  ONLY_VIDEOS: /(\.mp4)$/i,
  TEXTFIELD: /^[^\s]+(\s+[^\s]+)*$/,
  ONLY_SPECIAL_CHAR: /^[^a-zA-Z0-9]+$/,
  NO_SPECIAL_CHARACTER: /^([A-Za-z])+[^*|\":<>[\]{}`\\()';@&$]+$/,
  VALID_NAME: /^[a-zA-Z',"-+@\s]+/,
  FULL_NAME: /^[a-zA-Z]+ [a-zA-Z]+$/,
};
