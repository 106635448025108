import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input,
  Image,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useSelector } from "react-redux";
import {
  cancelRescheduleReasons,
  cancelRescheduleReasonsExpert,
  cancelRescheduleReasonsAdmin,
} from "../../../constant/selectOptions";
import { WARNING_YELLOW } from "../../../constant";

const RescheduledDetails = ({
  data,
  handleFormDataChange,
  isRescheduled,
  reschedulingPerson,
  rescheduledBy,
}) => {
  const user = useSelector((state) => state?.user?.profile);
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#A6B8C4",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
  };
  return (
    <SimpleGrid gap="16px" m="16px">
      {isRescheduled ? (
        <>
          <GridItem>
            <FormControl isInvalid={data?.reasonError}>
              <FormLabel className="personalFormLabel">
                Reason for reschedule
              </FormLabel>

              <Select
                onChange={(e) =>
                  handleFormDataChange("reason", e, "reasonError")
                }
                name="gender"
                value={data?.reason}
                selectedOptionStyle="check"
                placeholder="Select a reason"
                className="profileFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={
                  reschedulingPerson === "Parent" || rescheduledBy === "Parent"
                    ? cancelRescheduleReasons
                    : reschedulingPerson === "Expert" ||
                      rescheduledBy === "Expert"
                    ? cancelRescheduleReasonsExpert
                    : cancelRescheduleReasonsAdmin
                }
                isSearchable={false}
                chakraStyles={selectStyles}
              />

              {data?.reasonError && (
                <FormErrorMessage className="personalErrorMessage">
                  {data?.reasonError}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={data?.additionalDetailsError}>
              <FormLabel className="personalFormLabel">
                Additional details (Optional)
              </FormLabel>
              <InputGroup>
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      e.target.name,
                      e.target.value,
                      "fullNameError"
                    )
                  }
                  name="additionalDetails"
                  value={data?.additionalDetails}
                  placeholder="Type here..."
                  maxLength={50}
                  border="1px solid #A6B8C4"
                  className="myProfileFormInput"
                />
              </InputGroup>
              {data?.additionalDetailsError && (
                <FormErrorMessage className="personalErrorMessage">
                  {data?.additionalDetailsError}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        </>
      ) : (
        // user?.currentBalance < 1 ? (
        //   <GridItem className="popupBody">
        //     <Flex mb="8px">
        //       <Image src={WARNING_YELLOW}></Image>
        //       <Text className="insufficientBalance">
        //         Insufficient balance to reschedule session!
        //       </Text>
        //     </Flex>
        //     Unfortunately, you have exhausted your session balance.If you wish to
        //     continue taking sessions with your KinderPass Experts, please purchase
        //     more sessions.
        //   </GridItem>
        // ) :
        <GridItem className="popupBody">
          <Flex mb="8px">
            <Image src={WARNING_YELLOW}></Image>
            <Text className="insufficientBalance">
              Insufficient balance to reschedule session!
            </Text>
          </Flex>
          Unable to reschedule your session as your balance does not cover the
          reschedule penalty.
        </GridItem>
      )}
    </SimpleGrid>
  );
};
export default RescheduledDetails;
