import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { partiallyAttendedUser } from "../../../constant/selectOptions";

const ReasonDetails = ({ data, handleFormDataChange }) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#A6B8C4",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
  };
  return (
    <SimpleGrid gap="16px" m="16px">
      <GridItem>
        <FormControl isInvalid={data?.partiallyAttendedUserError}>
          <FormLabel className="personalFormLabel">
            Partially attended user
          </FormLabel>

          <Select
            onChange={(e) =>
              handleFormDataChange(
                "partiallyAttendedUser",
                e,
                "partiallyAttendedUserError"
              )
            }
            name="partiallyAttendedUser"
            value={data?.partiallyAttendedUser}
            selectedOptionStyle="check"
            placeholder="Select a partially attended User"
            className="profileFormSelect"
            hideSelectedOptions={false}
            selectedOptionColorScheme="green"
            options={partiallyAttendedUser}
            isSearchable={false}
            chakraStyles={selectStyles}
          />

          {data?.partiallyAttendedUserError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.partiallyAttendedUserError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={data?.reasonError}>
          <FormLabel className="personalFormLabel">
            Reason for this session being partially attended
          </FormLabel>
          <InputGroup>
            <Input
              onChange={(e) =>
                handleFormDataChange(
                  e.target.name,
                  e.target.value,
                  "reasonError"
                )
              }
              name="reason"
              value={data?.reason}
              placeholder="Type here..."
              maxLength={250}
              border="1px solid #A6B8C4"
              className="myProfileFormInput"
            />
          </InputGroup>
          {data?.reasonError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.reasonError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};
export default ReasonDetails;
