import { useState } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "chakra-ui-steps";

import { ConsultationCancelRescheduleStepper } from "./consultationCancelRescheduleStepper";
import { updateConsultationStatusService } from "../../modules/services";
import {
  toggleAPILoader,
  toggleModalOpen,
  toggleSessionLoader,
  getSupportSessions,
} from "../../modules/actions";
import { Toaster } from "../../common/components/Toaster";
import "./consultationCancelReschedulePopup.css";

const ConsultationCancelReschedulePopup = ({
  isOpen,
  onClose,
  session,
  type,
  reqDataThearapistSessions,
}) => {
  const dispatch = useDispatch();
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);

  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const [cancelledBy, setCancelledBy] = useState("Admin");

  const handleNextStep = () => {
    if (activeStep === 0) {
      nextStep();
    } else {
      dispatch(toggleAPILoader(true));
      const reqData = {
        status: type === "Cancel" ? "Cancelled" : "Reschedule requested",
        statusDetails:
          type === "Cancel"
            ? "Cancelled By " + cancelledBy + " (Not charged)"
            : "Reschedule requested By " + cancelledBy,
      };
      updateConsultationStatusService(session.id, reqData)
        .then((res) => {
          Toaster(
            type === "Cancel" ? "Session Cancelled" : "Reschedule requested",
            "success",
            "top-left"
          );
          dispatch(toggleAPILoader(false));
          dispatch(toggleModalOpen(false));

          dispatch(toggleSessionLoader(true));
          dispatch(getSupportSessions(reqDataThearapistSessions));

          handleClosePopup();
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "error", "top-left");
        });
    }
  };
  const handleClosePopup = () => {
    dispatch(toggleModalOpen(false));
    setStep(0);
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        <ConsultationCancelRescheduleStepper
          activeStep={activeStep}
          session={session}
          type={type}
          cancelledBy={cancelledBy}
          setCancelledBy={setCancelledBy}
        />

        <ModalFooter mt="16px" borderTop="1px solid #a6b8c4">
          <Flex>
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={() => {
                handleClosePopup();
              }}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={handleNextStep}
            >
              {apiLoader ? <Spinner /> : activeStep === 0 ? "Next" : "Confirm"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConsultationCancelReschedulePopup;
