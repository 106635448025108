import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Text,
  ModalFooter,
  Flex,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { getDatewithDay } from "../../utils/customMoment";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "chakra-ui-steps";
import {
  toggleAPILoader,
  toggleModalOpen,
  toggleSessionLoader,
  getSupportSessions,
} from "../../modules/actions";
import { setAttendedConsultationService } from "../../modules/services";
import { Toaster } from "../../common/components/Toaster";
import "./attendConsultationPopup.css";

const AttendConsultationPopup = ({
  isOpen,
  onClose,
  session,
  reqDataThearapistSessions,
}) => {
  const dispatch = useDispatch();
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);

  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });
  const handleNextStep = () => {
    dispatch(toggleAPILoader(true));
    setAttendedConsultationService(session.id)
      .then((res) => {
        Toaster("Consultation Attended reported", "success", "top-left");
        dispatch(toggleAPILoader(false));
        dispatch(toggleModalOpen(false));
        dispatch(toggleSessionLoader(true));
        dispatch(getSupportSessions(reqDataThearapistSessions));
        handleClosePopup();
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error", "top-left");
      });
  };

  const handleClosePopup = () => {
    dispatch(toggleModalOpen(false));
    setStep(0);
    onClose();
  };
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      autoFocus={false}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        <ModalHeader className="popupHeader">
          You are about to mark the following consultation as attended and
          completed:
        </ModalHeader>
        <ModalBody className="popupBody">
          <Text className="sessionDetailsDate" mb="20px">
            {session.sessionParentName + "-" + session.sessionStudentName} /{" "}
            {session.sessionExpertName}
          </Text>

          <Text className="sessionDetailsDate" mb="20px">
            {session.serviceType}
          </Text>

          <Flex mb="10px">
            <Text className="sessionDetailsDate">
              {getDatewithDay(session?.sessionDate)}
            </Text>
            <Text style={{ margin: "-5px 8px" }}>.</Text>
            <Text className="sessionDetailsDate">
              {session?.startTime + " - " + session?.endTime}
            </Text>
          </Flex>

          <Flex mb="10px">
            <Text className="sessionDetailsDate" color="#024742">
              New Status: Attended
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter mt="16px" borderTop="1px solid #a6b8c4">
          <Flex>
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={() => {
                onClose();
              }}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={handleNextStep}
            >
              {apiLoader ? <Spinner /> : "Confirm"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default AttendConsultationPopup;
