import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Grid,
  GridItem,
  Image,
  Text,
  Flex,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  AUDIO_ICON,
  IMG_ICON,
  DOC_ICON,
  BLANK_FAV,
  PDF_ICON,
  FAV,
  VIDEO_ICON,
  PPT_ICON,
} from "../../../../constant";

import {
  getDomainResources,
  toggleMainLoader,
} from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";
import { Toaster } from "../../../../common/components/Toaster";
import { addFavouriteResourceService } from "../../../../modules/services";
import FileViwer from "../../../../components/FileViewer";

const AllResources = ({ domain, type }) => {
  const dispatch = useDispatch();
  const resources = useSelector((state) => state?.resources);
  const user = useSelector((state) => state?.user?.profile);
  const loader = useSelector((state) => state?.loader.mainLoader);
  const [showResources, setShowResources] = useState([]);

  useEffect(() => {
    if (type === "expert") {
      dispatch(toggleMainLoader(true));
      dispatch(getDomainResources(domain.id));
    }
  }, [type]);

  useEffect(() => {
    setShowResources(resources);
  }, [resources]);

  const handleNavigateFirstPage = (d) => {
    // setSelectedCard(d);
    // setNavigateToGoal(true);
    window.open(d.url);
  };

  const handleFavouriteResource = (event, id) => {
    if (event.target.id === "innerDiv") {
      event.stopPropagation();
      dispatch(toggleMainLoader(true));
      window.scroll(0, 0);
      addFavouriteResourceService(id)
        .then((res) => {
          Toaster(res.data.message, "success");
          dispatch(getDomainResources(domain.id));
        })
        .catch((error) => {
          console.log(error.response);
          dispatch(toggleMainLoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm.match("^\\s+$")) {
      return;
    }
    let filteredResults = [];
    if (searchTerm.trim().length > 2) {
      filteredResults = resources.filter((item) => {
        const goalNames = item.goalName.toLowerCase();
        const outcomeNames = item.outcomeName.toLowerCase();
        const subGoalNames = item.subGoalName.toLowerCase();
        const searchLower = searchTerm.toLowerCase();
        const name = item.name.toLowerCase();

        return (
          goalNames.includes(searchLower) ||
          outcomeNames.includes(searchLower) ||
          subGoalNames.includes(searchLower) ||
          name.includes(searchLower)
        );
      });
      setShowResources(filteredResults);
    } else {
      setShowResources(resources);
    }
  };

  return (
    <>
      {loader ? (
        <Loader text="Loading resources..." />
      ) : (
        <Box mt="24px" p={type === "parent" ? "60px" : ""}>
          <Box
            fontSize="28px"
            fontWeight="500"
            color="#141719"
            lineHeight="36px"
          >
            {domain.name}
          </Box>
          <Box
            mt="8px"
            mb="24px"
            fontSize="16px"
            fontWeight="400"
            color="#3d464d"
            lineHeight="24px"
          >
            {type === "parent" ? domain.childGoal : domain.description}
          </Box>

          {type === "parent" ? null : (
            <Grid templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}>
              <GridItem colSpan={8}>
                <InputGroup>
                  <InputLeftElement
                    className="serachIcon"
                    pointerEvents="none"
                    children={
                      <SearchIcon className="serachIcon" color="#4299E1" />
                    }
                    size="xs"
                  />
                  <Input
                    className="resourecsSerachInput"
                    size="xs"
                    placeholder="Search for exercise name, skill name, goal name here"
                    onChange={handleSearch}
                  />
                </InputGroup>
              </GridItem>
            </Grid>
          )}

          <Grid
            my="24px"
            columnGap="37.5px"
            wrap="wrap"
            rowGap="24px"
            templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
            maxHeight="90vh"
            overflowY="auto"
          >
            {showResources.length > 0 ? (
              showResources?.map((d, index) => (
                <GridItem
                  border="1px solid #a6b8c4"
                  borderRadius="10px"
                  cursor="pointer"
                  onClick={() => handleNavigateFirstPage(d)}
                  p="16px"
                  colSpan={{ md: 4, sm: 12 }}
                  flexDir="column"
                  key={index}
                  position="relative"
                >
                  {/* <Flex align="top" justify="space-between"> */}
                  <Image
                    h="32px"
                    w="32px"
                    src={
                      d.type === "application/pdf"
                        ? PDF_ICON
                        : d.type.includes("presentation") ||
                          d.type ===
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                          d.type === "application/vnd.google-apps.presentation"
                        ? PPT_ICON
                        : d.type === "image/png"
                        ? IMG_ICON
                        : d.type === "video/mp4"
                        ? VIDEO_ICON
                        : DOC_ICON
                    }
                  />
                  {/* <Image h="20px" w="20px" src={FAV} />
        </Flex> */}
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="20px"
                    letterSpacing="0.1px"
                  >
                    {d.name}
                  </Text>
                  <Text
                    color="#5a646b"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    marginTop="8px"
                    mb="8px"
                  >
                    {d.goalName + "-" + d.subGoalName}
                  </Text>
                  <Flex
                    align="center"
                    justify="end"
                    className="resourcesFavIcon"
                  >
                    <Image
                      h="20px"
                      w="20px"
                      id="innerDiv"
                      src={
                        d?.favouriteUserIds?.includes(user.id) ? FAV : BLANK_FAV
                      }
                      onClick={(e) => handleFavouriteResource(e, d.id)}
                    />
                  </Flex>
                </GridItem>
              ))
            ) : (
              <GridItem
                p="16px"
                colStart={{ md: 4 }}
                colEnd={10}
                flexDir="column"
              >
                Opps! Searched resource not found..
              </GridItem>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AllResources;
