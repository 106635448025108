import {
  ModalBody,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

const ReschedulingPerson = ({ title, rescheduledBy, setRescheduledBy }) => {
  return (
    <>
      <ModalHeader className="popupHeader">{title}</ModalHeader>
      <ModalBody className="popupBody">
        <RadioGroup onChange={setRescheduledBy} value={rescheduledBy}>
          <Stack direction="row">
            <Radio value="Parent">Parent</Radio>
            <Radio value="Expert">Expert</Radio>
            <Radio value="Admin">Admin</Radio>
          </Stack>
        </RadioGroup>
      </ModalBody>
    </>
  );
};
export default ReschedulingPerson;
