import AuthenticatedContainer from "../../../container/Authenticated";

const SupportData = () => {
  return (
    <AuthenticatedContainer>
      <div>Support Data Page</div>
    </AuthenticatedContainer>
  );
};

export default SupportData;
