import { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Select } from "chakra-react-select";
import { sessionType } from "../../../../constant";
import Loader from "../../../../common/components/Loader";

const SessionDetails = ({ sessionData, handleFormDataChange }) => {
  const allExperts = useSelector((state) => state?.allExperts);
  const expertsParents = useSelector((state) => state?.expertsParents);
  const { mainLoader } = useSelector((state) => state?.loader);

  const [expertOptions, setExpertOptions] = useState([]);
  const [parentOptions, setParentOptions] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);

  useEffect(() => {
    const options = allExperts?.map((item) => {
      return {
        value: item.id,
        label: item.fullName,
        expertDetails: item,
      };
    });
    setExpertOptions(options);
  }, [allExperts]);

  useEffect(() => {
    const options = expertsParents?.map((item) => {
      return {
        value: item.id,
        label: item.fullName,
        parentDetails: item,
      };
    });
    setParentOptions(options);
  }, [expertsParents]);

  useEffect(() => {
    const options = [];
    sessionData.parent?.parentDetails?.myExperts?.map((item) => {
      if (item.educatorId === sessionData?.expert?.value) {
        item.service.map((service) => {
          options.push({
            value: service,
            label: service,
          });
        });
      }
    });

    setServiceTypeOptions(options);
  }, [sessionData?.expert, sessionData?.parent]);

  return (
    <Box>
      {mainLoader ? (
        <Loader text="Loading....." />
      ) : (
        <SimpleGrid gap="16px" mt="16px">
          <GridItem>
            <FormControl isInvalid={sessionData?.expertError}>
              <FormLabel className="personalFormLabel">Expert</FormLabel>
              <Select
                onChange={(e) =>
                  handleFormDataChange("expert", e, "expertError")
                }
                name="expert"
                value={sessionData?.expert}
                selectedOptionStyle="check"
                placeholder="Select expert"
                className="profileFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={expertOptions}
                isSearchable={true}
              />
              {sessionData?.expertError && (
                <FormErrorMessage className="personalErrorMessage">
                  {sessionData?.expertError}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={sessionData?.parentError}>
              <FormLabel className="personalFormLabel">Child/Parent</FormLabel>
              <Select
                onChange={(e) =>
                  handleFormDataChange("parent", e, "parentError")
                }
                name="parent"
                value={sessionData?.parent}
                selectedOptionStyle="check"
                placeholder="Select child and parent"
                className="profileFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={parentOptions}
                isSearchable={true}
                isDisabled={sessionData?.expert === ""}
              />
              {sessionData?.parentError && (
                <FormErrorMessage className="personalErrorMessage">
                  {sessionData?.parentError}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={sessionData?.sessionTypeError}>
              <FormLabel className="personalFormLabel">Session Type</FormLabel>
              <Select
                onChange={(e) =>
                  handleFormDataChange(
                    "sessionType",
                    e.value,
                    "sessionTypeError"
                  )
                }
                name="sessionType"
                value={{
                  label: sessionData?.sessionType,
                  value: sessionData?.sessionType,
                }}
                selectedOptionStyle="check"
                placeholder="Select session type(duration)"
                className="profileFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={sessionType}
                isSearchable={false}
              />
              {sessionData?.sessionTypeError && (
                <FormErrorMessage className="personalErrorMessage">
                  {sessionData?.sessionTypeError}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={sessionData?.serviceError}>
              <FormLabel className="personalFormLabel">Service Type</FormLabel>
              <Select
                onChange={(e) =>
                  handleFormDataChange("service", e.value, "serviceError")
                }
                name="service"
                value={{
                  label: sessionData?.service,
                  value: sessionData?.service,
                }}
                selectedOptionStyle="check"
                placeholder="Select service type"
                className="profileFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={serviceTypeOptions}
                isSearchable={false}
                isDisabled={sessionData?.parent === ""}
              />
              {sessionData?.serviceError && (
                <FormErrorMessage className="personalErrorMessage">
                  {sessionData?.serviceError}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        </SimpleGrid>
      )}
    </Box>
  );
};
export default SessionDetails;
