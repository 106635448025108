import {
  ModalBody,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

const CancellingPerson = ({ title, cancelledBy, setCancelledBy }) => {
  return (
    <>
      <ModalHeader className="popupHeader">{title}</ModalHeader>
      <ModalBody className="popupBody">
        <RadioGroup onChange={setCancelledBy} value={cancelledBy}>
          <Stack direction="row">
            <Radio value="Parent">Parent</Radio>
            <Radio value="Expert">Expert</Radio>
            <Radio value="Admin">Admin</Radio>
          </Stack>
        </RadioGroup>
      </ModalBody>
    </>
  );
};
export default CancellingPerson;
