import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useSteps } from "chakra-ui-steps";
import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { CLOSE } from "../../../constant/images";
import { ScheduleSessionSupportStepper } from "./scheduleSessionSupportStepper";
import { Toaster } from "../../../common/components/Toaster";
import {
  toggleAPILoader,
  toggleMainLoader,
  getParentSessions,
  getUserProfile,
  getAllExperts,
  getExpertsParents,
  getSupportSessions,
} from "../../../modules/actions";
import { scheduleSessionSupportService } from "../../../modules/services";
import "./scheduleSessionSupport.css";

const ScheduleSessionSupport = ({ setOverlayOpen, reqDataSupport }) => {
  const dispatch = useDispatch();

  const { mainLoader, apiLoader } = useSelector((state) => state?.loader);

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const [calenderStartDate, setCalenderStartDate] = useState();
  const [selectedSlot, setSelectedSlot] = useState("");
  const [sessionData, setSessionData] = useState({
    expert: "",
    expertError: "",
    service: "",
    serviceError: "",
    parent: "",
    parentError: "",
    sessionType: "",
    sessionTypeError: "",
    startTime: "",
    startTimeError: "",
    endTime: "",
    endTimeError: "",
    noOfWeeks: "",
    noOfWeeksError: "",
    days: [],
    daysError: "",
    isMultiple: false,
    isMultipleError: "",
    startDate: "",
    startDateError: "",
  });

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getAllExperts());
  }, []);

  const handleFormDataChange = (name, value, errorName) => {
    console.log(name, value);
    if (name === "expert") {
      dispatch(toggleMainLoader(true));
      dispatch(getExpertsParents(value.value));
      setSessionData({
        ...sessionData,
        [name]: value,
        [errorName]: "",
        parent: "",
        parentError: "",
        service: "",
        serviceError: "",
      });
    } else if (name === "parent") {
      setSessionData({
        ...sessionData,
        [name]: value,
        [errorName]: "",
        service: "",
        serviceError: "",
      });
    } else if (name === "startTime") {
      let choosenSlot = {
        date: sessionData?.startDate,
        slotStartTime: value,
        slotDuration:
          sessionData?.sessionType === "Full session paid" ? 60 : 30,
      };
      setSelectedSlot(choosenSlot);
      setSessionData({ ...sessionData, [name]: value, [errorName]: "" });
    } else if (name === "startTimeChange") {
      setSessionData({
        ...sessionData,
        startTime: "",
        startTimeError: "",
        endTime: "",
        endTimeError: "",
      });
    } else {
      setSessionData({ ...sessionData, [name]: value, [errorName]: "" });
    }
  };
  console.log(sessionData, "sessionData");

  const handleCalendarChange = (event) => {
    const selectedDate = moment(event);
    setCalenderStartDate(selectedDate);
    handleFormDataChange("startDate", selectedDate, "startDateError");
  };

  const handleNextStep = () => {
    const {
      expert,
      service,
      parent,
      sessionType,
      isMultiple,
      noOfWeeks,
      startDate,
      startTime,
      endTime,
      days,
    } = sessionData;
    if (activeStep === 0) {
      const validateExpert = !expert ? "Please choose your expert" : "";
      const validateService = !service ? "Please choose your service" : "";
      const validateParent = !parent ? "Please choose a child" : "";
      const validateSessionType = !sessionType
        ? "Please choose a session type"
        : "";

      setSessionData({
        ...sessionData,
        expertError: validateExpert,
        serviceError: validateService,
        parentError: validateParent,
        sessionTypeError: validateSessionType,
      });
      if (
        !validateExpert &&
        !validateService &&
        !validateParent &&
        !validateSessionType
      ) {
        nextStep();
      }
    } else {
      const validateStartDate = !startDate ? "Please choose a date" : "";
      const validateStartTime =
        !selectedSlot && !startTime
          ? "Please choose start time or choose a slot"
          : "";
      // const validateEndTime =
      //   startTime && !endTime ? "Please choose end time" : "";
      const validateNoOfWeeks =
        isMultiple && !noOfWeeks ? "Please enter no of weeks" : "";
      const validateDays =
        isMultiple && days.length === 0 ? "Please choose days" : "";

      setSessionData({
        ...sessionData,
        startDateError: validateStartDate,
        startTimeError: validateStartTime,
        // endTimeError: validateEndTime,
        noOfWeeksError: validateNoOfWeeks,
        daysError: validateDays,
      });

      if (
        !validateStartDate &&
        !validateStartTime &&
        // !validateEndTime &&
        !validateNoOfWeeks &&
        !validateDays
      ) {
        const reqData = {
          expert: expert?.value,
          parent: parent?.value,
          service: service,
          choosenSlot: selectedSlot,
          noOfWeeks: noOfWeeks,
          selectedDays: days.map(({ value }) => value),
          isMultiple: isMultiple,
        };
        if (selectedSlot) {
          scheduleSessionApiCall(reqData);
        }
      }
    }
  };

  const scheduleSessionApiCall = (reqData) => {
    dispatch(toggleAPILoader(true));
    scheduleSessionSupportService(reqData)
      .then((response) => {
        if (response.data.data.length === 0 && response.data.message) {
          Toaster(response.data.message, "error", "top-left");
          dispatch(toggleAPILoader(false));
        } else {
          Toaster("Sessions scheduled successfully", "success");
          dispatch(toggleAPILoader(false));
          dispatch(toggleMainLoader(true));
          dispatch(getSupportSessions(reqDataSupport));
          setOverlayOpen(false);
        }
      })
      .catch((error) => {
        Toaster(error.response.data.message, "error", "top-left");
        dispatch(toggleAPILoader(false));
      });
  };

  const handleBackBtn = () => {
    if (activeStep === 0) {
      setOverlayOpen(false);
    } else {
      prevStep();
    }
  };

  return (
    <Box>
      <Box className="overlayComponentMainBox"></Box>
      <Box className="overlayComponentContentBoxParent">
        <Flex justifyContent="space-between" alignItems="center">
          <Text className="editProfileTitle">Schedule New Session</Text>
          <Image
            onClick={() => setOverlayOpen(false)}
            src={CLOSE}
            height="24px"
            width="24px"
            cursor="pointer"
            mr="16px"
          />
        </Flex>
        <Flex className="overlayComponentContentBoxFlexParent"></Flex>
        <Box className="overlayComponentContentBoxChildren">
          <ScheduleSessionSupportStepper
            sessionData={sessionData}
            handleFormDataChange={handleFormDataChange}
            handleCalendarChange={handleCalendarChange}
            setSelectedSlot={setSelectedSlot}
            calenderStartDate={calenderStartDate}
            activeStep={activeStep}
          />
        </Box>
        <Flex className="overlayComponentContentBoxBtnsFlex">
          <Button
            onClick={handleBackBtn}
            className="overlayComponentContentBoxBtn1"
          >
            Back
          </Button>
          {activeStep === 0 ? (
            <Button
              className="overlayComponentContentBoxBtn2"
              color={
                apiLoader ||
                mainLoader ||
                !sessionData?.expert ||
                !sessionData?.service ||
                !sessionData?.sessionType ||
                !sessionData?.parent
                  ? "#91A2AE"
                  : "white"
              }
              bg={
                apiLoader ||
                mainLoader ||
                !sessionData?.expert ||
                !sessionData?.service ||
                !sessionData?.sessionType ||
                !sessionData?.parent
                  ? "#F3F8FB"
                  : "#4299E1"
              }
              onClick={handleNextStep}
              isDisabled={
                // btnDisabled ||
                !sessionData?.expert ||
                !sessionData?.service ||
                !sessionData?.sessionType ||
                !sessionData?.parent
              }
            >
              {apiLoader ? <Spinner /> : <Text>Next</Text>}
            </Button>
          ) : (
            <Button
              className="overlayComponentContentBoxBtn2"
              color={selectedSlot.length < 1 ? "#91A2AE" : "white"}
              bg={selectedSlot.length < 1 ? "#F3F8FB" : "#4299E1"}
              onClick={handleNextStep}
              isDisabled={selectedSlot.length < 1}
            >
              {apiLoader ? <Spinner /> : <Text>Confirm</Text>}
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
export default ScheduleSessionSupport;
