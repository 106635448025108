import React, { useEffect, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import {
  Avatar,
  Flex,
  Grid,
  GridItem,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ACTIVE_SORTING_ICON,
  OPEN_PATHWAY,
  INACTIVE_SORTING_ICON,
  ROUTES_PATH,
} from "../../../constant";
import AuthenticatedContainer from "../../../container/Authenticated";
import { getAllParents, toggleMainLoader } from "../../../modules/actions";
import Loader from "../../../common/components/Loader";
import {
  getDateMonthYear,
  getDateMonthYear1,
} from "../../../utils/customMoment";
import "./parents.css";

const SupportParents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allParents = useSelector((state) => state?.allParents);
  const { mainLoader } = useSelector((state) => state?.loader);
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Parent",
        accessor: "fullName", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "alphanumeric",
      },
      {
        Header: "Child",
        accessor: "childName", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "alphanumeric",
      },
      {
        Header: "Last Session",
        accessor: "lastSession", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "date",
      },
      {
        Header: "Next Session",
        accessor: "nextSession", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "date",
      },
      {
        Header: "Due Date",
        accessor: "validTill", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "date",
      },
      // {
      //   Header: "Reminder Sent",
      //   accessor: "reminderSent",
      //   id: "reminderSent",
      //   disableSortBy: true,
      // },

      {
        Header: "Balance",
        accessor: "currentBalance", // sort
        disableSortBy: false,
        sort: "asc",
        sortable: true,
        sortType: "alphanumeric",
      },
      {
        Header: "id",
        accessor: "id",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getAllParents());
    setData([]);
  }, []);

  useEffect(() => {
    setData(allParents);
  }, [allParents]);

  const handleParentDetails = (data) => {
    navigate(ROUTES_PATH.SUPPORT_PARENTS_DETAILS, {
      state: { details: data },
    });
  };
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Details",
        Header: "Details",
        Cell: ({ row }) => (
          <Image
            cursor="pointer"
            onClick={() => handleParentDetails(row.original)}
            src={OPEN_PATHWAY}
          />
        ),
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultCanSort: true, // Enable sorting by default
        sortTypes: {
          alphanumeric: (rowA, rowB, columnId) => {
            const valueA = rowA.values[columnId];
            const valueB = rowB.values[columnId];
            // Case-sensitive string comparison
            return valueA?.localeCompare(valueB, undefined, {
              sensitivity: "base",
            });
          },
          date: (rowA, rowB, columnId) => {
            const valueA = new Date(rowA.values[columnId]);
            const valueB = new Date(rowB.values[columnId]);
            return valueA - valueB;
          },
        },
      },
      tableHooks,
      useSortBy
    );
  return (
    <AuthenticatedContainer>
      {mainLoader ? (
        <Loader text="“Loading.....”" />
      ) : (
        <Grid maxW="100vw" row px={{ base: "50px", lg: "71px" }} py="24px">
          <Text className="mainHeader">Parents</Text>
          <GridItem className="mainScheduleBox">
            <Table border="1px solid #d8e5ee" {...getTableProps()}>
              <Thead>
                {headerGroups?.map((hg) => (
                  <Tr {...hg.getHeaderGroupProps()}>
                    {hg.headers?.map((column) => (
                      <Th
                        className="tableHeader"
                        textTransform=""
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Flex gap="8px" align="center">
                          {column.id !== "id" && column.render("Header")}
                          {column.id !== "Details" && column.id !== "id" ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <Image
                                  style={{ transform: "rotate(0.5turn)" }}
                                  src={ACTIVE_SORTING_ICON}
                                />
                              ) : (
                                <Image src={ACTIVE_SORTING_ICON} />
                              )
                            ) : (
                              <Image src={INACTIVE_SORTING_ICON} />
                            )
                          ) : (
                            ""
                          )}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows?.map((r) => {
                  prepareRow(r);
                  return (
                    <Tr {...r.getRowProps()}>
                      {r.cells?.map((c) => (
                        <Td className="tableData" {...c.getCellProps()}>
                          {c.column.id === "childName" ? (
                            <Flex gap="8px" align="center">
                              <Avatar
                                size="sm"
                                className="tableStudentAvatar"
                                name={c.value}
                                src={c.row.original.image}
                              />
                              <Text>{c.value}</Text>
                            </Flex>
                          ) : c.column.id === "lastSession" ? (
                            c.value ? (
                              getDateMonthYear(c.value)
                            ) : (
                              "-"
                            )
                          ) : c.column.id === "nextSession" ? (
                            c.value ? (
                              getDateMonthYear(c.value)
                            ) : (
                              "-"
                            )
                          ) : c.column.id === "validTill" ? (
                            c.value ? (
                              getDateMonthYear1(c.value)
                            ) : (
                              "-"
                            )
                          ) : c.column.id === "currentBalance" ? (
                            c.value ? (
                              Number(c.value) > 0 ? (
                                Number(c.value).toFixed(2)
                              ) : (
                                0
                              )
                            ) : (
                              "-"
                            )
                          ) : c.column.id !== "id" ? (
                            c.render("Cell")
                          ) : (
                            ""
                          )}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </GridItem>
        </Grid>
      )}
    </AuthenticatedContainer>
  );
};

export default SupportParents;
