import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Tag,
  Avatar,
  TagLabel,
  Flex,
  Text,
  useDisclosure,
  Button,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { useNavigate, Link } from "react-router-dom";
import {
  ATTENDED,
  SCHEDULED,
  CANCELLED,
  RESCHEDULED,
  PARTIALLY_ATTENDED,
  NO_SHOW,
  FILE,
  OPEN,
  ONGOING,
} from "../../constant/images";
import {
  getDatewithDay,
  getDateMonthYear1,
  isSameOrBeforeTodaysDate,
} from "../../utils/customMoment";
import {
  getUserData,
  toggleMainLoader,
  getStudentsNotesAction,
  toggleModalOpen,
  toggleAPILoader,
  toggleSessionLoader,
  getSupportSessions,
} from "../../modules/actions";
import {
  calculateAge,
  handleStringManupulation,
  istTime2,
  istTime,
} from "../../utils/customHooks";
import Loader from "../../common/components/Loader";
import { ROUTES_PATH } from "../../constant";
import RescheduledPopup from "../RescheduledPopup";
import CancellationPopup from "../CancellationPopup";
import {
  joinConsulatationService,
  setOngoingSessionService,
  startMeetingService,
} from "../../modules/services";
import { Toaster } from "../../common/components/Toaster";
import PartiallyAttendedPopup from "../PartiallyAttendedPopup";
import ReportNoShowPopup from "../ReportNoShow";
import AttendConsultationPopup from "../AttendConsultationPopup";
import ConsultationCancelReschedulePopup from "../ConsultationCancelReschedulePopup";

const SessionDetailsTab = ({ selectedSession, reqData, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parentData = useSelector((state) => state.userData);
  const mainLoader = useSelector((state) => state.loader?.mainLoader);
  const apiLoader = useSelector((state) => state.loader?.apiLoader);
  const { notes, profile } = useSelector((state) => state?.user);
  const [popupType, setPopupType] = useState("");
  const {
    isOpen: isRescheduleOpen,
    onOpen: onRescheduleOpen,
    onClose: onRescheduleClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();

  const {
    isOpen: isPartiallyAttenedOpen,
    onOpen: onPartiallyAttenedOpen,
    onClose: onPartiallyAttenedClose,
  } = useDisclosure();
  const {
    isOpen: isReportNoShowOpen,
    onOpen: onReportNoShowOpen,
    onClose: onReportNoShowClose,
  } = useDisclosure();
  const {
    isOpen: isAttendConsultationOpen,
    onOpen: onAttendConsultationOpen,
    onClose: onAttendConsultationClose,
  } = useDisclosure();
  const {
    isOpen: isConsultationCancelRescheduleOpen,
    onOpen: onConsultationCancelRescheduleOpen,
    onClose: onConsultationCancelRescheduleClose,
  } = useDisclosure();

  useEffect(() => {
    if (
      selectedSession.sessionType !== "Free Consultation" &&
      selectedSession.sessionType !== "Paid Consultation"
    ) {
      dispatch(toggleMainLoader(true));
      dispatch(getUserData(selectedSession?.sessionStudentId));
      dispatch(getStudentsNotesAction(selectedSession?.sessionStudentId));
    }
  }, [selectedSession]);

  const joinButtonVisibility = () => {
    if (
      profile?.role === "expert" &&
      !selectedSession.attendedBy.expert &&
      (selectedSession.status === "Scheduled" ||
        selectedSession.status === "Parent Joined")
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );

      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = selectedSession.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );

      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      //Check if now is greater or equal to session start date time
      let currentTime = new Date();
      let now = istTime(currentTime);

      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    } else if (
      (profile?.role === "support" ||
        profile?.role === "sales-admin" ||
        profile?.role === "program") &&
      !selectedSession.attendedBy.admin &&
      (selectedSession.status == "Expert Joined" ||
        selectedSession.status == "Parent Joined" ||
        selectedSession.status == "Ongoing")
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = selectedSession["startTime"].split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      let endTime = selectedSession["endTime"].split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  };
  const rejoinBtnVisibility = () => {
    if (
      profile?.role === "expert" &&
      selectedSession.attendedBy.expert &&
      (selectedSession.status === "Expert Joined" ||
        selectedSession.status === "Parent Joined" ||
        selectedSession.status === "Ongoing")
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = selectedSession.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if (
        selectedSession.sessionType !== "Free Consultation" &&
        selectedSession.sessionType !== "Paid Consultation"
      ) {
        if (now.getTime() <= exactSessionDate.getTime() - 10 * 60 * 1000) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    } else if (
      (profile?.role === "support" ||
        profile?.role === "sales-admin" ||
        profile?.role === "program") &&
      selectedSession.attendedBy.admin &&
      (selectedSession["status"] == "Expert Joined" ||
        selectedSession["status"] == "Parent Joined" ||
        selectedSession["status"] == "Ongoing")
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = selectedSession.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if (
        selectedSession.sessionType != "Free Consultation" &&
        selectedSession.sessionType != "Paid Consultation"
      ) {
        if (now.getTime() <= exactSessionDate.getTime() - 10 * 60 * 1000) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    }
    return false;
  };
  const attendedButtonVisibility = () => {
    if (
      (profile?.role === "support" ||
        profile?.role === "sales-admin" ||
        profile?.role === "program") &&
      (selectedSession.status == "Update Status" ||
        selectedSession.status == "Ongoing")
    ) {
      let sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      var startTime = selectedSession.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() > exactJoinEndTime.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  };

  const noShowButtonVisibility = () => {
    if (
      profile?.role === "support" ||
      profile?.role === "sales-admin" ||
      profile?.role === "program"
    ) {
      let sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      // startTime is HH:MM
      var startTime = selectedSession.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() > exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      } else {
        return false;
      }
    }
  };

  const setOngoingVisibility = () => {
    if (
      selectedSession.status == "Parent Joined" ||
      selectedSession.status == "Expert Joined"
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = selectedSession.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }

    return false;
  };

  const consultationJoinButtonVisibility = () => {
    if (
      profile?.role === "expert" &&
      (selectedSession.status === "Scheduled" ||
        (selectedSession.status === "Ongoing" &&
          !selectedSession.attendedBy.expert))
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = selectedSession.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    } else if (
      (profile?.role === "support" ||
        profile?.role === "sales-admin" ||
        profile?.role === "program") &&
      !selectedSession.attendedBy.admin &&
      (selectedSession["status"] == "Expert Joined" ||
        selectedSession["status"] == "Parent Joined" ||
        selectedSession["status"] == "Ongoing")
    ) {
      let sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = selectedSession["startTime"].split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);
      let endTime = selectedSession["endTime"].split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  };
  const showUrl = () => {
    //showing session url if it exists and if now <= sessionEndDateTime
    if (
      selectedSession["status"] == "Expert Joined" ||
      selectedSession["status"] == "Parent Joined" ||
      selectedSession["status"] == "Ongoing"
    ) {
      let currentTime = new Date();
      let now = istTime(currentTime);

      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );

      let exactSessionEndTime = istTime2(sessionDate);

      let endTime = selectedSession["endTime"].split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionEndTime.setMinutes(
        exactSessionEndTime.getMinutes() + endingHour * 60 + endingMinute - 10
      );

      if (selectedSession.zoomJoinUrl && now <= exactSessionEndTime)
        return true;
    }
    return false;
  };

  const handleJoinMeeting = async () => {
    const reqData = {
      sessionId: selectedSession.id,
      role: profile?.role,
    };
    dispatch(toggleAPILoader(true));
    startMeetingService(reqData)
      .then((res) => {
        const session = res.data.data;
        dispatch(toggleAPILoader(false));
        window.open(session["zoomJoinUrl"]);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  const rejoinConsultation = () => {
    let url = selectedSession.meetingLink;
    if (!url.match(/^https?:\/\//i)) url = "https://" + url;
    window.open(url, "_blank");
  };
  const handleJoinConsulatation = () => {
    dispatch(toggleAPILoader(true));
    joinConsulatationService(selectedSession.id)
      .then((res) => {
        const session = res.data.data;
        dispatch(toggleAPILoader(false));
        let url = selectedSession.meetingLink;
        if (!url.match(/^https?:\/\//i)) url = "https://" + url;
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  const handleCopyMeetingLink = () => {
    navigator.clipboard.writeText(selectedSession?.zoomJoinUrl);
    Toaster("Copied successfully!", "success");
  };
  const handleSetOngoing = () => {
    dispatch(toggleAPILoader(true));
    setOngoingSessionService(selectedSession.id)
      .then((res) => {
        Toaster("Session status changed to Ongoing", "success", "top-left");
        dispatch(toggleAPILoader(false));
        dispatch(toggleSessionLoader(true));
        dispatch(getSupportSessions(reqData));
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  return (
    <Box p="16px">
      <Flex>
        <Tag
          className="txnsSecondMapFlexBoxTag"
          style={{
            backgroundColor:
              ((selectedSession?.status === "Successfull" ||
                selectedSession?.status === "Attended" ||
                selectedSession?.status === "Partially Attended") &&
                "#EFF7E6") ||
              (selectedSession?.status === "Cancelled" && "#FFDAD6") ||
              (selectedSession?.status === "Scheduled" && "#F3EBFF") ||
              ((selectedSession?.status === "Rescheduled" ||
                selectedSession?.status === "Reschedule requested") &&
                "#FEDFA0") ||
              ((selectedSession?.status === "noShow-Parent" ||
                selectedSession?.status === "noShow-Expert" ||
                selectedSession?.status === "No-show-by-Parent" ||
                selectedSession?.status === "No-show-by-Expert") &&
                "#ffffff") ||
              (selectedSession?.status === "Ongoing" && "#F3EBFF"),
            border:
              (selectedSession?.status === "noShow-Parent" ||
                selectedSession?.status === "noShow-Expert" ||
                selectedSession?.status === "No-show-by-Parent" ||
                selectedSession?.status === "No-show-by-Expert") &&
              "1px solid #BA1A1A",
          }}
        >
          <Avatar
            className="txnsSecondMapFlexBoxTagAvatar"
            src={
              ((selectedSession?.status === "Successfull" ||
                selectedSession?.status === "Attended") &&
                ATTENDED) ||
              (selectedSession?.status === "Partially Attended" &&
                PARTIALLY_ATTENDED) ||
              (selectedSession?.status === "Cancelled" && CANCELLED) ||
              (selectedSession?.status === "Scheduled" && SCHEDULED) ||
              ((selectedSession?.status === "Rescheduled" ||
                selectedSession?.status === "Reschedule requested") &&
                RESCHEDULED) ||
              ((selectedSession?.status === "noShow-Parent" ||
                selectedSession?.status === "noShow-Expert" ||
                selectedSession?.status === "No-show-by-Parent" ||
                selectedSession?.status === "No-show-by-Expert") &&
                NO_SHOW) ||
              (selectedSession?.status === "Ongoing" && ONGOING)
            }
          />
          <TagLabel
            style={{
              color:
                ((selectedSession?.status === "Successfull" ||
                  selectedSession?.status === "Attended" ||
                  selectedSession?.status === "Partially Attended") &&
                  "#024742") ||
                (selectedSession?.status === "Cancelled" && "#410002") ||
                (selectedSession?.status === "Scheduled" && "#2F0D63") ||
                ((selectedSession?.status === "Rescheduled" ||
                  selectedSession?.status === "Reschedule requested") &&
                  "#734110") ||
                ((selectedSession?.status === "noShow-Parent" ||
                  selectedSession?.status === "noShow-Expert" ||
                  selectedSession?.status === "No-show-by-Parent" ||
                  selectedSession?.status === "No-show-by-Expert") &&
                  "#BA1A1A") ||
                (selectedSession?.status === "Ongoing" && "#8751D9"),
            }}
          >
            {selectedSession?.status}
          </TagLabel>
        </Tag>
        <Box className="sessionTypeTag">
          {selectedSession.sessionType === "Free Consultation" ||
          selectedSession.sessionType === "Paid Consultation"
            ? "Consultation"
            : "Session"}
        </Box>
      </Flex>
      <Text className="sessionDetailsExpert">
        {selectedSession.sessionParentName +
          "-" +
          selectedSession.sessionStudentName}{" "}
        / {selectedSession.sessionExpertName}
      </Text>
      {(profile?.role === "support" ||
        profile?.role === "sales-admin" ||
        profile?.role === "program") && (
        <Flex mb="10px">
          <Text className="sessionDetailsDate">Id: </Text>
          <Text className="sessionDetailsDate" ml="5px">
            {selectedSession?.id}
          </Text>
        </Flex>
      )}

      <Flex>
        {" "}
        <Text className="sessionDetailsDate">
          {getDatewithDay(selectedSession?.sessionDate)}
        </Text>
        <Text style={{ margin: "-5px 8px" }}>.</Text>
        <Text className="sessionDetailsDate">
          {selectedSession?.startTime + " - " + selectedSession?.endTime}
        </Text>
      </Flex>

      {(selectedSession.sessionType === "Free Consultation" ||
        selectedSession.sessionType === "Paid Consultation") && (
        <>
          <Text className="sessionDetailsProfileLink" mt="10px">
            {selectedSession?.meetingLink}
          </Text>
          {(profile?.role === "support" ||
            profile?.role === "sales-admin" ||
            profile?.role === "program") && (
            <Text className="sessionDetailsDate" mt="10px">
              Scheduled By: {selectedSession?.createdByName}
            </Text>
          )}
        </>
      )}

      {(profile?.role === "support" ||
        profile?.role === "sales-admin" ||
        profile?.role === "program") && (
        <>
          {showUrl() && (
            <Text className="sessionDetailsProfileLink" mt="10px">
              {selectedSession?.zoomJoinUrl}
            </Text>
          )}
          {selectedSession.sessionType !== "Free Consultation" &&
          selectedSession.sessionType !== "Paid Consultation" &&
          selectedSession?.status === "Cancelled" ? (
            <Flex mt="10px">
              <Text className="sessionDetailsDate">
                {selectedSession?.status +
                  " on " +
                  getDatewithDay(selectedSession?.cancelledOn) +
                  " by " +
                  selectedSession?.cancellingPerson}{" "}
              </Text>
            </Flex>
          ) : (
            <Flex mt="10px">
              <Text className="sessionDetailsDate">
                {selectedSession?.statusDetails}
              </Text>
            </Flex>
          )}
          {selectedSession?.status === "Rescheduled" && (
            <Flex mt="10px">
              <Text className="sessionDetailsDate">
                {selectedSession?.status +
                  " on " +
                  getDatewithDay(selectedSession?.rescheduledOn) +
                  " by " +
                  selectedSession?.reschedulingPerson}{" "}
              </Text>
            </Flex>
          )}

          {selectedSession?.meetingHostEmail && (
            <Flex mt="10px">
              <Text className="sessionDetailsDate">Zoom Email: </Text>
              <Text className="sessionDetailsDate" ml="5px">
                {selectedSession?.meetingHostEmail}
              </Text>
            </Flex>
          )}
          {selectedSession?.zoomLicenseReleased && (
            <Flex mb="10px">
              <Text className="sessionDetailsDate">Zoom Id Released: </Text>
              <Text
                className="sessionDetailsDate"
                ml="5px"
                color={
                  selectedSession?.zoomLicenseReleased ? "#67CA5F" : "#FF6D6D"
                }
              >
                {selectedSession?.zoomLicenseReleased ? "Yes" : "No"}
              </Text>
            </Flex>
          )}
        </>
      )}

      {selectedSession.sessionType === "Free Consultation" ||
      selectedSession.sessionType === "Paid Consultation" ? (
        <Box
          className="sessionBtnsDiv"
          py="16px"
          borderBottom="2px solid #D8E5EE"
        >
          {consultationJoinButtonVisibility() && (
            <Button
              className="sesssionJoinBtn"
              w="165px"
              minH="40px"
              onClick={handleJoinConsulatation}
              p="10px 20px !important"
            >
              {apiLoader ? <Spinner /> : "Join"}
            </Button>
          )}
          {rejoinBtnVisibility() && (
            <Button
              className="sesssionJoinBtn"
              onClick={() => rejoinConsultation()}
            >
              {apiLoader ? <Spinner /> : "ReJoin"}
            </Button>
          )}

          {noShowButtonVisibility() && (
            <Button
              className="sesssionOtherBtns"
              w="165px"
              minH="40px"
              onClick={() => {
                dispatch(toggleModalOpen(true));
                onReportNoShowOpen();
              }}
              p="10px 20px !important"
            >
              {apiLoader ? <Spinner /> : "Report No-Show"}
            </Button>
          )}
          {attendedButtonVisibility() && (
            <Button
              className="sesssionOtherBtns"
              w="165px"
              minH="40px"
              onClick={() => {
                dispatch(toggleModalOpen(true));
                onAttendConsultationOpen();
              }}
              p="10px 20px !important"
            >
              {apiLoader ? <Spinner /> : "Attended"}
            </Button>
          )}

          {(profile?.role === "support" ||
            profile?.role === "sales-admin" ||
            profile?.role === "program") &&
            selectedSession.status === "Scheduled" &&
            !isSameOrBeforeTodaysDate(selectedSession?.sessionEndDateTime) && (
              <>
                <Button
                  className="sesssionOtherBtns"
                  w="165px"
                  minH="40px"
                  onClick={() => {
                    dispatch(toggleModalOpen(true));
                    setPopupType("Reschedule");
                    onConsultationCancelRescheduleOpen();
                  }}
                  p="10px 20px !important"
                >
                  Reschedule
                </Button>

                <Button
                  className="sesssionOtherBtns"
                  w="165px"
                  minH="40px"
                  onClick={() => {
                    dispatch(toggleModalOpen(true));
                    setPopupType("Cancel");
                    onConsultationCancelRescheduleOpen();
                  }}
                  p="10px 20px !important"
                >
                  Cancel
                </Button>
              </>
            )}
        </Box>
      ) : (
        <Box
          className="sessionBtnsDiv"
          py="16px"
          borderBottom="2px solid #D8E5EE"
        >
          {joinButtonVisibility() && (
            <Button
              className="sesssionJoinBtn"
              w="150px"
              minH="40px"
              onClick={handleJoinMeeting}
              p="10px 20px !important"
            >
              {apiLoader ? <Spinner /> : "Join"}
            </Button>
          )}
          {rejoinBtnVisibility() && (
            <Button
              className="sesssionJoinBtn"
              w="150px"
              minH="40px"
              onClick={() => handleJoinMeeting()}
            >
              {apiLoader ? <Spinner /> : "ReJoin"}
            </Button>
          )}
          {(profile?.role === "support" ||
            profile?.role === "sales-admin" ||
            profile?.role === "program") && (
            <>
              {showUrl() && (
                <Button
                  className="sesssionOtherBtns"
                  w="150px"
                  minH="40px"
                  onClick={() => handleCopyMeetingLink()}
                >
                  Copy Link
                </Button>
              )}
              {setOngoingVisibility() && (
                <Button
                  className="sesssionOtherBtns"
                  w="165px"
                  minH="40px"
                  onClick={() => handleSetOngoing()}
                >
                  {apiLoader ? <Spinner /> : "Set Ongoing"}
                </Button>
              )}
              {(selectedSession.status === "Ongoing" ||
                selectedSession.status === "Attended") && (
                <Button
                  className="sesssionOtherBtns"
                  minW="175px"
                  minH="40px"
                  fontSize="12px"
                  onClick={() => {
                    dispatch(toggleModalOpen(true));
                    onPartiallyAttenedOpen();
                  }}
                >
                  {apiLoader ? <Spinner /> : "Mark as Partially Attended"}
                </Button>
              )}
            </>
          )}
          {selectedSession?.sessionType !== "Free Consultation" &&
            selectedSession?.sessionType !== "Paid Consultation" &&
            (selectedSession.status === "Scheduled" ||
              selectedSession.status === "Session Request") &&
            !isSameOrBeforeTodaysDate(selectedSession?.sessionEndDateTime) && (
              <>
                <Button
                  className="sesssionOtherBtns"
                  w="165px"
                  minH="40px"
                  onClick={() => {
                    dispatch(toggleModalOpen(true));
                    onRescheduleOpen();
                  }}
                  p="10px 20px !important"
                >
                  Reschedule
                </Button>

                <Button
                  className="sesssionOtherBtns"
                  w="165px"
                  minH="40px"
                  onClick={() => {
                    dispatch(toggleModalOpen(true));
                    onCancelOpen();
                  }}
                  p="10px 20px !important"
                >
                  Cancel
                </Button>
              </>
            )}
        </Box>
      )}

      {mainLoader ? (
        <Loader text="Loading details..." />
      ) : (
        <>
          <Box py="24px">
            <Text className="sessionDetailsHeading">Child</Text>
            <Flex justifyContent="space-between" alignItems="center">
              {" "}
              <Flex>
                <Avatar
                  size="md"
                  name={selectedSession?.sessionStudentName}
                  src=""
                  marginRight="8px"
                />
                <Box>
                  <Text className="sessionDetailsChildName">
                    {selectedSession?.sessionStudentName}
                  </Text>
                  {selectedSession?.sessionType === "Free Consultation" ||
                  selectedSession?.sessionType === "Paid Consultation" ? (
                    <Text className="sessionDetailsChildAge">
                      {selectedSession?.dateOfBirth}
                    </Text>
                  ) : (
                    <Text className="sessionDetailsChildAge">
                      {calculateAge(parentData && parentData?.childDob)}
                    </Text>
                  )}
                </Box>
              </Flex>
              {selectedSession?.sessionType !== "Free Consultation" &&
                selectedSession?.sessionType !== "Paid Consultation" &&
                type === "Expert" && (
                  <Text
                    className="sessionDetailsProfileLink"
                    onClick={() =>
                      navigate(ROUTES_PATH.MY_STUDENTS, { state: parentData })
                    }
                  >
                    Open Profile
                  </Text>
                )}
            </Flex>
          </Box>
          <Box pb="24px" borderBottom="2px solid #D8E5EE">
            <Text className="sessionDetailsHeading">Parent</Text>
            <Flex>
              <Avatar
                size="md"
                name={selectedSession?.sessionParentName}
                src=""
                marginRight="8px"
              />
              <Box>
                <Text className="sessionDetailsChildName">
                  {selectedSession?.sessionParentName}
                </Text>
                {selectedSession?.sessionType === "Free Consultation" &&
                  selectedSession?.sessionType === "Paid Consultation" && (
                    <Text className="sessionDetailsChildAge">
                      {parentData?.relationWithChild}
                    </Text>
                  )}
              </Box>
            </Flex>
          </Box>
          <Box py="24px">
            <Text className="sessionDetailsHeading">Service Type Concern</Text>
            <Text className="sessionDetailsChildAge" color="#141719">
              {selectedSession?.serviceType}
            </Text>
          </Box>
          {selectedSession?.sessionType === "Free Consultation" ||
          selectedSession?.sessionType === "Paid Consultation" ? (
            <Box pb="24px" borderBottom="2px solid #D8E5EE">
              <Text className="sessionDetailsHeading">Primary Langauge</Text>
              <Text className="sessionDetailsChildAge" color="#141719">
                {selectedSession.primaryLanguage}
              </Text>

              <Text className="sessionDetailsHeading" mt="24px">
                Secondary Langauges
              </Text>
              <Text className="sessionDetailsChildAge" color="#141719">
                {handleStringManupulation(selectedSession?.secondaryLanguages)}
              </Text>
            </Box>
          ) : (
            <Box pb="24px" borderBottom="2px solid #D8E5EE">
              <Text className="sessionDetailsHeading">Langauges</Text>
              <Text className="sessionDetailsChildAge" color="#141719">
                {handleStringManupulation(parentData?.languages)}
              </Text>
            </Box>
          )}

          <Box py="24px" borderBottom="2px solid #D8E5EE">
            {(selectedSession?.sessionType === "Free Consultation" ||
              selectedSession?.sessionType === "Paid Consultation") &&
            type === "Expert" ? (
              <>
                <Text className="sessionDetailsHeading">Child Concern</Text>
                <Text className="sessionDetailsChildAge" color="#141719">
                  {selectedSession?.childConcern}
                </Text>

                <Text className="sessionDetailsHeading" mt="24px">
                  Child Condition
                </Text>
                <Text className="sessionDetailsChildAge" color="#141719">
                  {handleStringManupulation(selectedSession?.childCondition)}
                </Text>
                <Text className="sessionDetailsHeading" mt="24px">
                  ACR Report
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Flex>
                    <Image src={FILE} alt="file" marginRight="8px" />
                    <Box>
                      <Text className="sessionDetailsChildName">
                        ACR Report
                      </Text>
                      <Text className="sessionDetailsChildAge">
                        Last updated:
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Link
                      to={"/expert/acr-report/" + selectedSession?.id}
                      target="_blank"
                      className="sessionDetailsProfileLink"
                      textDecorationLine="none"
                    >
                      Open
                      <Image
                        src={OPEN}
                        alt=""
                        marginLeft="8px"
                        // onClick={() => navigate(ROUTES_PATH.ACRREPORT)}
                        cursor="pointer"
                      />
                    </Link>
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                <Text className="sessionDetailsHeading">Notes</Text>
                <Text
                  className="sessionDetailsChildAge"
                  color="#141719"
                  textTransform="none"
                  mb="8px !important"
                >
                  Here is some random note that was added some time ago
                </Text>
                {notes?.map((note, index) => (
                  <Box key={index}>
                    <Flex mb="8px">
                      <Text className="sessionDetailsDate">
                        {getDateMonthYear1(note?.updatedOn)}
                      </Text>
                      <Text style={{ margin: "-5px 8px" }}>.</Text>
                      <Text className="sessionDetailsDate">
                        {note?.expertName}
                      </Text>
                    </Flex>

                    <div
                      className="sessionDetailsChildAge"
                      style={{
                        textTransform: "none",
                        marginBottom: "16px",
                        color: "#141719",
                      }}
                      dangerouslySetInnerHTML={{ __html: note.content }}
                    ></div>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </>
      )}
      <RescheduledPopup
        isOpen={isRescheduleOpen}
        onClose={onRescheduleClose}
        title="Reschedule session?"
        reschedulingPerson={type}
        session={selectedSession}
        type="rescheduled"
        reqDataThearapistSessions={reqData}
      />
      <CancellationPopup
        isOpen={isCancelOpen}
        onClose={onCancelClose}
        title="Cancel session?"
        cancellingPerson={type}
        session={selectedSession}
        type="cancelled"
        reqDataThearapistSessions={reqData}
      />
      <PartiallyAttendedPopup
        isOpen={isPartiallyAttenedOpen}
        onClose={onPartiallyAttenedClose}
        session={selectedSession}
        reqDataSupport={reqData}
      />
      <ReportNoShowPopup
        isOpen={isReportNoShowOpen}
        onClose={onReportNoShowClose}
        session={selectedSession}
        reqDataThearapistSessions={reqData}
      />
      <AttendConsultationPopup
        isOpen={isAttendConsultationOpen}
        onClose={onAttendConsultationClose}
        session={selectedSession}
        reqDataThearapistSessions={reqData}
      />
      <ConsultationCancelReschedulePopup
        isOpen={isConsultationCancelRescheduleOpen}
        onClose={onConsultationCancelRescheduleClose}
        session={selectedSession}
        type={popupType}
        reqDataThearapistSessions={reqData}
      />
    </Box>
  );
};
export default SessionDetailsTab;
