import { useEffect } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import {
  CLOSE,
  LEFT_CARET_BLACK,
  RIGHT_CARET_BLACK,
} from "../../../constant/images";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SessionDetailsTab from "../../../components/SessionDetailsTab";
import SessionHistoryTab from "../../../components/SessionHistoryTab";
import SessionPathwayTab from "../../../components/SessionPathwayTab";
import "../MyStudents/myStudents.css";
import "./sessionDetailsPopup.css";

const SessionOverlay = ({
  setOverlayOpen,
  closer,
  selectedSession,
  therapistSessions,
  setSelectedSession,
  reqData,
  type,
}) => {
  const modalOpen = useSelector((state) => state?.modalOpen);

  const [tab, setTab] = useState("Details");
  const [sessionsArray, setSessionsArray] = useState(false);
  const [index, setIndex] = useState("");

  useEffect(() => {
    const sessions = [].concat(...Object.values(therapistSessions?.sessions));
    setSessionsArray(sessions);
    const session = sessions.find((data) => data.id === selectedSession.id);
    if (session.status !== selectedSession.status) {
      setSelectedSession(session);
    }
    const idx = sessions.indexOf(session);
    setIndex(idx);
  }, [therapistSessions, selectedSession]);

  const handleOverlayCloser = () => {
    setSelectedSession("");
    setOverlayOpen(false);
  };

  const handleTabChanges = (tab) => {
    setTab(tab);
  };
  const handleBackSession = () => {
    setSelectedSession(sessionsArray[index - 1]);
    setIndex(index - 1);
  };

  const handleNextSession = () => {
    const index = sessionsArray.indexOf(selectedSession);
    setSelectedSession(sessionsArray[index + 1]);
    setIndex(index + 1);
  };

  return (
    <Box>
      <Box
        className={
          !modalOpen ? "overlayComponentMainBox" : "overlayComponentMainBox1"
        }
      ></Box>
      <Box
        className={
          !modalOpen
            ? "overlayComponentContentBox"
            : "overlayComponentContentBox1"
        }
      >
        <Flex justifyContent="space-between">
          <Flex pl="12px" alignItems="center">
            <Image
              src={LEFT_CARET_BLACK}
              mr="25px"
              cursor={index > 0 ? "pointer" : "not-allowed"}
              opacity={index > 0 ? "100%" : "10%"}
              onClick={index > 0 ? handleBackSession : null}
              height="15px"
              // width="8px"
            />
            <Image
              src={RIGHT_CARET_BLACK}
              onClick={
                index !== sessionsArray.length - 1 ? handleNextSession : null
              }
              opacity={index !== sessionsArray.length - 1 ? "100%" : "10%"}
              cursor={
                index !== sessionsArray.length - 1 ? "pointer" : "not-allowed"
              }
              height="15px"
              // width="8px"
            />
          </Flex>

          {/* <Text className="overlayComponentContentBoxFlexTitle">{title}</Text> */}
          <Image
            className="overlayComponentContentBoxFlexImage"
            onClick={handleOverlayCloser}
            src={CLOSE}
          />
        </Flex>
        <Flex className="tabsDiv">
          <Box
            className={tab === "Details" ? "activeTabText" : "tabText"}
            onClick={() => handleTabChanges("Details")}
          >
            Details
          </Box>
          {selectedSession?.sessionType !== "Free Consultation" &&
            selectedSession?.sessionType !== "Paid Consultation" &&
            type === "Expert" && (
              <>
                <Box
                  className={tab === "Pathway" ? "activeTabText" : "tabText"}
                  onClick={() => handleTabChanges("Pathway")}
                >
                  Pathway
                </Box>
                <Box
                  className={
                    tab === "Session History" ? "activeTabText" : "tabText"
                  }
                  onClick={() => handleTabChanges("Session History")}
                >
                  Session History
                </Box>
              </>
            )}
        </Flex>
        {/* className="overlayComponentContentBoxChildren" */}
        <Box className="sessionoverlayChildren">
          {tab === "Details" ? (
            <SessionDetailsTab
              selectedSession={selectedSession}
              reqData={reqData}
              type={type}
            />
          ) : tab === "Pathway" ? (
            <SessionPathwayTab selectedSession={selectedSession} />
          ) : (
            <SessionHistoryTab selectedSession={selectedSession} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SessionOverlay;
