import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  cancelRescheduleReasons,
  cancelRescheduleReasonsExpert,
  cancelRescheduleReasonsAdmin,
} from "../../../constant/selectOptions";

const CancellationDetails = ({
  data,
  handleFormDataChange,
  cancellingPerson,
  cancelledBy,
}) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#A6B8C4",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
  };
  return (
    <SimpleGrid gap="16px" m="16px">
      <GridItem>
        <FormControl isInvalid={data?.reasonError}>
          <FormLabel className="personalFormLabel">
            Reason for cancellation
          </FormLabel>

          <Select
            onChange={(e) => handleFormDataChange("reason", e, "reasonError")}
            name="gender"
            value={data?.reason}
            selectedOptionStyle="check"
            placeholder="Select a reason"
            className="profileFormSelect"
            hideSelectedOptions={false}
            selectedOptionColorScheme="green"
            options={
              cancellingPerson === "Parent" || cancelledBy === "Parent"
                ? cancelRescheduleReasons
                : cancellingPerson === "Expert" || cancelledBy === "Expert"
                ? cancelRescheduleReasonsExpert
                : cancelRescheduleReasonsAdmin
            }
            isSearchable={false}
            chakraStyles={selectStyles}
          />

          {data?.reasonError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.reasonError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={data?.additionalDetailsError}>
          <FormLabel className="personalFormLabel">
            Additional details (Optional)
          </FormLabel>
          <InputGroup>
            <Input
              onChange={(e) =>
                handleFormDataChange(
                  e.target.name,
                  e.target.value,
                  "fullNameError"
                )
              }
              name="additionalDetails"
              value={data?.additionalDetails}
              placeholder="Type here..."
              maxLength={50}
              border="1px solid #A6B8C4"
              className="myProfileFormInput"
            />
          </InputGroup>
          {data?.additionalDetailsError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.additionalDetailsError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};
export default CancellationDetails;
