import { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "chakra-ui-steps";
import { PartiallyAttendedStepper } from "./partiallyAttendedStepper";
import {
  toggleModalOpen,
  toggleSessionLoader,
  toggleAPILoader,
  getSupportSessions,
} from "../../modules/actions";
import { Toaster } from "../../common/components/Toaster";
import "./partiallyAttended.css";
import { setPartiallyAtttendedSessionService } from "../../modules/services";

const PartiallyAttendedPopup = ({
  isOpen,
  onClose,
  session,
  reqDataSupport,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.profile);
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const [data, setData] = useState({
    reason: "",
    reasonError: "",
    partiallyAttendedUser: "",
    partiallyAttendedUserError: "",
    compensationToExpert: "",
    compensationToExpertError: "",
    compensationToParent: "",
    compensationToParentError: "",
  });
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleFormDataChange = (name, value, errorName) => {
    setBtnDisabled(false);
    setData({ ...data, [name]: value, [errorName]: "" });
  };
  console.log(data, "data");
  const handleNextStep = () => {
    const {
      reason,
      compensationToExpert,
      compensationToParent,
      partiallyAttendedUser,
    } = data;

    if (activeStep === 0) {
      const validateReason = !reason ? "Please enter a valid reason" : "";
      const validatePartiallyAttendedUser = !partiallyAttendedUser
        ? "Please choose a partially attended user"
        : "";

      setData({
        ...data,
        reasonError: validateReason,
        partiallyAttendedUserError: validatePartiallyAttendedUser,
      });

      if (!validateReason && !validatePartiallyAttendedUser) {
        nextStep();
      }
    } else {
      const validateCompensationToExpert = !compensationToExpert
        ? "Please enter a correct compensation to expert"
        : "";
      const validateCompensationToParent = !compensationToParent
        ? "Please choose a correct compensation to parent"
        : "";

      setData({
        ...data,
        compensationToExpertError: validateCompensationToExpert,
        compensationToParentError: validateCompensationToParent,
      });

      if (!validateCompensationToExpert && !validateCompensationToParent) {
        const reqData = {
          adminName: user?.fullName,
          expertCompensation: Number(compensationToExpert),
          parentCompensation: Number(compensationToParent),
          partiallyAttendedBy: partiallyAttendedUser.value,
          partiallyAttendedreason: reason,
        };
        dispatch(toggleAPILoader(true));
        setPartiallyAtttendedSessionService(session.id, reqData)
          .then((res) => {
            Toaster(
              "Session status set as Partially Attended",
              "success",
              "top-left"
            );
            dispatch(toggleAPILoader(false));
            dispatch(toggleModalOpen(false));
            dispatch(toggleSessionLoader(true));
            dispatch(getSupportSessions(reqDataSupport));
            handleClosePopup();
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleAPILoader(false));
            Toaster(error.response.data.message, "error", "top-left");
          });
      }
    }
  };
  const handleClosePopup = () => {
    dispatch(toggleModalOpen(false));
    setStep(0);
    setData({
      reason: "",
      reasonError: "",
      partiallyAttendedUser: "",
      partiallyAttendedUserError: "",
      compensationToExpert: "",
      compensationToExpertError: "",
      compensationToParent: "",
      compensationToParentError: "",
    });
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        <PartiallyAttendedStepper
          activeStep={activeStep}
          data={data}
          handleFormDataChange={handleFormDataChange}
          session={session}
        />

        <ModalFooter
          mt="16px"
          borderTop="1px solid #a6b8c4"
          // justifyContent="space-between"
        >
          {/* <Text
            className="readPolicyText"
            onClick={() =>
              window.open(
                cancellingPerson === "Parent"
                  ? "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Session+Cancellations+Policy+by+Client+(1).pdf"
                  : "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Session+Cancellations+Policy+by+Expert+(1).pdf"
              )
            }
          >
            Read policy
          </Text> */}

          <Flex>
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={() => {
                handleClosePopup();
              }}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={handleNextStep}
              isDisabled={btnDisabled}
            >
              {apiLoader ? <Spinner /> : activeStep === 0 ? "Next" : "Confirm"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PartiallyAttendedPopup;
