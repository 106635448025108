export const ROUTES_PATH = {
  SCHEDULE: "/expert/schedules",
  SIGNIN: "/expert/signin",
  EXPERT_VERIFICATION_PENDING: "/expert/verification-pending",
  EXPRT_REGISTER_SUCCESS: "/expert/register-success",
  MY_STUDENTS: "/expert/my-students",
  STUDENTS_PATHWAY: "/expert/my-students/pathway",
  RESOURCE: "/expert/resources",
  PROFILE: "/expert/profile",
  ONBOARDING: "/expert/onboarding",
  ACRREPORT: "/expert/acr-report/:consultationId",
  PARENT_SIGNIN: "/parent/signin",
  PARENT_HOME: "/parent/home",
  PARENT_EXERCISE: "/parent/exercise",
  PARENT_PROGRESS: "/parent/progress",
  PARENT_SESSION: "/parent/session",
  PARENT_EXPLORE: "/parent/explore",
  PARENT_PROFILE: "/parent/profile",
  EXPERT_PATHWAY: "/expert/my-students/pathway",
  EXPERT_ONBOARDING_RESOURCES: "/expert/resources/tarining-onboarding",
  PARENT_EXERCISE_RESOURCE: "/parent/exercise/resources",
  EXPERT_FEEDBACK: "expert/feedback/:userId/:sessionId",
  PARENT_FEEDBACK: "parent/feedback/:userId/:sessionId",
  PARENT_CHOOSE_PLAN: "/parent/choose-plan",
  PARENT_ACTIVITIES: "/parent/explore/activity",
  PARENT_ARTICLES: "/parent/explore/article",
  PARENT_LIVE_BOOKING: "/parent/explore/workshops",
  SUPPORT_SIGNIN: "/support/signin",
  SUPPORT_SCHEDULE: "/support/schedule",
  SUPPORT_PARENTS: "/support/parents",
  SUPPORT_PARENTS_DETAILS: "/support/parents/details",
  SUPPORT_EXPERT: "/support/experts",
  SUPPORT_DATA: "/support/data",
};
