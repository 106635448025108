import { Grid, GridItem, Text, Image, Box, Tooltip } from "@chakra-ui/react";
import {
  getDayNumber1,
  getMonthName1,
  getDateMonthYear1,
  getDayName1,
  isTodaysDate,
  isSameOrBeforeTodaysDate,
} from "../../utils/customMoment";
import { istTime2, istTime } from "../../utils/customHooks";
import {
  ATTENDED,
  CANCELLED,
  NO_SHOW,
  ORANGE_WARNING,
  PARTIALLY_ATTENDED,
  REQUEST_SESSION,
  RESCHEDULED,
  SCHEDULED,
  ONGOING,
  UPDATE_STATUS,
  WARNING,
} from "../../constant";

const ScheduleCalenderBody = ({
  sessionsData,
  setOverlayOpen,
  setSelectedSession,
  selectedSession,
}) => {
  let keys;
  if (sessionsData) {
    keys = Object.keys(sessionsData);
  }
  const handleSessionSelection = (session) => {
    setOverlayOpen(true);
    setSelectedSession(session);
  };

  const currentSessionVisibility = (selectedSession) => {
    if (
      selectedSession.status !== "Rescheduled" &&
      selectedSession.status !== "Cancelled" &&
      selectedSession.status !== "Attended" &&
      selectedSession.status !== "Session Request Expired" &&
      selectedSession.status !== "Rejected Session Request" &&
      selectedSession.status !== "REQUEST CANCELLED"
    ) {
      const sessionDate = new Date(
        selectedSession.sessionDate._seconds * 1000 +
          selectedSession.sessionDate._nanoseconds / 1000000
      );

      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = selectedSession.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 10
      );

      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() +
          startingHour * 60 +
          startingMinute +
          selectedSession.slotDuration
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        return true;
      }
    }

    return false;
  };
  return (
    <>
      {keys?.map((sessionDate, index) => {
        return (
          <Grid
            templateColumns="repeat(12, 1fr)"
            borderBottom="1px solid #D8E5EE"
            key={index}
          >
            <GridItem colSpan={2} className="calenderDateCol">
              <Text
                className={
                  isTodaysDate(sessionDate) ? "activeDateCss" : "dateNoCss"
                }
              >
                {getDayNumber1(sessionDate)}
              </Text>
              <Text className="monthDayText">
                {getMonthName1(sessionDate)}-{getDayName1(sessionDate)}
              </Text>
            </GridItem>
            <GridItem colSpan={10}>
              {sessionsData[sessionDate].map((session, index) => {
                return (
                  <Grid
                    templateColumns="repeat(12, 1fr)"
                    key={index}
                    onClick={() => {
                      handleSessionSelection(session);
                    }}
                    style={{
                      background:
                        selectedSession.id === session.id ? "#FCFAF4" : "",
                      // opacity: "100%",
                      borderRadius: "36px",
                      cursor: "pointer",
                      borderTop: currentSessionVisibility(session)
                        ? "2px solid #FAB84B"
                        : "",
                    }}
                  >
                    <GridItem colSpan={7} className="sessionExpertNameDiv">
                      <Tooltip
                        label={
                          session.status === "Rescheduled" &&
                          session.rescheduledDate
                            ? session.status +
                              " on " +
                              getDateMonthYear1(session.rescheduledDate)
                            : session.status === "Scheduled" &&
                              !isSameOrBeforeTodaysDate(
                                session.sessionEndDateTime
                              )
                            ? "Upcoming"
                            : session.status === "Update Status"
                            ? "Wait status updating"
                            : session.status
                        }
                        hasArrow
                        className="tooltipCss"
                      >
                        <Image
                          src={
                            session.status === "Scheduled" ||
                            session.status === "Expert Joined" ||
                            session.status === "Parent Joined"
                              ? SCHEDULED
                              : session.status === "Rescheduled" ||
                                session.status === "Reschedule requested"
                              ? RESCHEDULED
                              : session.status === "Cancelled"
                              ? CANCELLED
                              : session.status === "Partially Attended"
                              ? PARTIALLY_ATTENDED
                              : session.status === "Session Request" ||
                                session.status === "Session Request Expired" ||
                                session.status === "Rejected Session Request" ||
                                session.status === "REQUEST CANCELLED"
                              ? REQUEST_SESSION
                              : session?.status === "noShow-Parent" ||
                                session?.status === "noShow-Expert" ||
                                session?.status === "No-show-by-Parent" ||
                                session?.status === "No-show-by-Expert"
                              ? NO_SHOW
                              : session.status === "Attended"
                              ? ATTENDED
                              : session.status === "Update Status"
                              ? UPDATE_STATUS
                              : session.status === "Ongoing" ||
                                session.status === "Starting"
                              ? ONGOING
                              : session.status
                          }
                          alt={session.status}
                        />
                      </Tooltip>
                      <Text className="sessionTime">
                        {session.startTime} - {session.endTime}
                      </Text>
                      <Text className="sessionExpertName">
                        {session.sessionParentName +
                          "-" +
                          session.sessionStudentName}{" "}
                        / {session.sessionExpertName}
                      </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                      <Box className="sessionTypeTagDiv" key={index}>
                        {(session.sessionType === "Free Consultation" ||
                          session.sessionType === "Paid Consultation") && (
                          <Box className="updateACRTag">
                            <Image src={WARNING} marginRight="8px" />
                            Update ACR
                          </Box>
                        )}
                        {(session.status === "Session Request" ||
                          session.status === "Session Request Expired" ||
                          session.status === "Rejected Session Request" ||
                          session.status === "REQUEST CANCELLED") && (
                          <Box className="sessionRequestTag">
                            <Image src={ORANGE_WARNING} marginRight="8px" />
                            {session.status}
                          </Box>
                        )}
                        {(session.status === "noShow-Parent" ||
                          session.status === "noShow-Expert") && (
                          <Box className="noShowTag">
                            {session.status === "noShow-Parent"
                              ? "No show by parent"
                              : "No show by expert"}
                          </Box>
                        )}
                        {(session.status === "Expert Joined" ||
                          session.status === "Parent Joined") && (
                          <Box className="joinedTag">{session.status}</Box>
                        )}
                        <Box className="sessionTypeTag">
                          {session.sessionType === "Free Consultation" ||
                          session.sessionType === "Paid Consultation"
                            ? "Consultation"
                            : "Session"}
                        </Box>
                      </Box>
                    </GridItem>
                  </Grid>
                );
              })}
            </GridItem>
          </Grid>
        );
      })}
    </>
  );
};
export default ScheduleCalenderBody;
