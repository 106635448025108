//Navbar images
import logo from "../assets/icons/logo.svg";
import calenderBlank from "../assets/icons/calendarBlank.svg";
import userList from "../assets/icons/userList.svg";
import books from "../assets/icons/books.svg";
import baby from "../assets/icons/baby.svg";
import calenderBlankActive from "../assets/icons/calendarBlankBlue.svg";
import userListActive from "../assets/icons/userListBlue.svg";
import booksActive from "../assets/icons/booksBlue.svg";
import babyActive from "../assets/icons/babyBlue.svg";
import profile from "../assets/icons/profile.svg";
import help from "../assets/icons/help.svg";
import search from "../assets/icons/search.svg";
import notification from "../assets/icons/notification.svg";
import activeHome from "../assets/icons/home.svg";
import home from "../assets/icons/homeBlack.svg";
import chartLine from "../assets/icons/chartLine.svg";
import activeChartLine from "../assets/icons/chartLineBlue.svg";
import sparkle from "../assets/icons/sparkle.svg";
import activeSparkle from "../assets/icons/sparkleBlue.svg";
import homeMobile from "../assets/icons/homeMobile.svg";
import chartLineMobile from "../assets/icons/chartLineMobile.svg";
import sparkleMobile from "../assets/icons/sparkleMobile.svg";
import calenderBlankMobile from "../assets/icons/calendarBlankMobile.svg";
import userListMobile from "../assets/icons/userListMobile.svg";
import homeActiveMobile from "../assets/icons/homeActiveMobile.svg";
import chartLineActiveMobile from "../assets/icons/chartLineActiveMobile.svg";
import sparkleActiveMobile from "../assets/icons/sparkleActiveMobile.svg";
import calenderBlankActiveMobile from "../assets/icons/calenderBlankActiveMobile.svg";
import userListActiveMobile from "../assets/icons/userListActiveMobile.svg";
import personIcon from "../assets/icons/person.svg";
import expertIcon from "../assets/icons/expertIcon.svg";
import dataIcon from "../assets/icons/dataIcon.svg";
import personActiveIcon from "../assets/icons/personActive.svg";
import expertActiveIcon from "../assets/icons/expertActiveIcon.svg";
import dataActiveIcon from "../assets/icons/dataActiveIcon.svg";

//Layout images
import layoutBanner from "../assets/images/layoutBg.svg";
import authBottomBg from "../assets/images/authBottomBg.svg";
import authBottomBgMobile from "../assets/images/authBottomBgMob.svg";

//Login images
import phone from "../assets/icons/phone.svg";
import googleLogo from "../assets/icons/google.svg";

//Schedule images
import backCaret from "../assets/icons/backCaret.svg";
import nextCaret from "../assets/icons/nextCaret.svg";
import attendedIcon from "../assets/icons/attendedIcon.svg";
import cancelledIcon from "../assets/icons/cancelledIcon.svg";
import partiallyAttendedIcon from "../assets/icons/partiallyAttendedIcon.svg";
import rescheduledIcon from "../assets/icons/rescheduledIcon.svg";
import scheduledIcon from "../assets/icons/scheduledIcon.svg";
import warning from "../assets/icons/warning.svg";
import sessionRequestIcon from "../assets/icons/sessionRequestIcon.svg";
import noShowIcon from "../assets/icons/noShowIcon.svg";
import orangeWarning from "../assets/icons/orangeWarning.svg";
import updateStatusIcon from "../assets/icons/updateStatusIcon.svg";
import playbtn from "../assets/icons/play.svg";
import rightCaretBlack from "../assets/icons/rightCaretBlack.svg";
import leftCaretBlack from "../assets/icons/leftCaretBlack.svg";
import file from "../assets/icons/file.svg";
import open from "../assets/icons/open.svg";
import childInfo from "../assets/icons/childInfo.svg";
import academicHistory from "../assets/icons/academicHistory.svg";
import medicalHistory from "../assets/icons/medicalHistory.svg";
import observations from "../assets/icons/observations.svg";
import girl from "../assets/icons/girl.svg";
import boy from "../assets/icons/boy.svg";
import checkMarkGreen from "../assets/icons/checkMarkGreen.svg";
import starIcon from "../assets/icons/starIcon.svg";
import plusIconBlack from "../assets/icons/plusIconBlack.svg";

//My Profile images
import closeIcon from "../assets/icons/close.svg";
import infoIcon from "../assets/icons/Info.svg";
import deleteIcon from "../assets/icons/Trash.svg";
import addIcon from "../assets/icons/PlusCircle.svg";
import userIcon from "../assets/icons/user.svg";
import plusIcon from "../assets/icons/plusIcon.svg";
import childDoc from "../assets/images/childDoc.svg";
import cancelRed from "../assets/icons/cancelRed.svg";

//My student images
import activeSorting from "../assets/icons/SortAscending.svg";
import inactiveSorting from "../assets/icons/inactiveSorting.svg";
import openPath from "../assets/icons/CaretRight.svg";
import closePath from "../assets/icons/CaretDown.svg";
import cake from "../assets/icons/Cake.svg";
import parent from "../assets/icons/parent.svg";
import lock from "../assets/icons/Lock.svg";
import top from "../assets/icons/ArrowUp.svg";
import trs from "../assets/icons/TRS.svg";
import slc from "../assets/icons/SLC.svg";
import phy from "../assets/icons/physical.svg";
import ew from "../assets/icons/EW.svg";
import nl from "../assets/icons/NL.svg";
import sh from "../assets/icons/SelfHelp.svg";
import back from "../assets/icons/back.svg";
import ongoing from "../assets/icons/ongoing.svg";
import accomplished from "../assets/icons/accomplished.svg";
import checked from "../assets/icons/completed.svg";
import next from "../assets/icons/next.svg";
import generalGroup from "../assets/icons/generalWhatsapp.svg";
import occupationGroup from "../assets/icons/occupationalGroup.svg";
import behaviourGroup from "../assets/icons/BehaviouralGroup.svg";
import speechGroup from "../assets/icons/speechTherapy.svg";
import drag from "../assets/icons/draggable.svg";

// Expert onboarding images
import pendingVerfication from "../assets/images/pending.png";
import uploadIcon from "../assets/icons/uploadIcon.png";
import noUserImage from "../assets/images/noUser.png";
import cancelTimeSlot from "../assets/icons/cancelTimeSlot.png";

// Expert resources image
import plusSign from "../assets/icons/add.svg";
import favSign from "../assets/icons/Heart.svg";
import videoIcon from "../assets/icons/videoIcon.svg";
import audioIcon from "../assets/icons/audioIcon.svg";
import pdfIcon from "../assets/icons/pdfIcon.svg";
import docIcon from "../assets/icons/docIcon.svg";
import imageIcon from "../assets/icons/imageIcon.png";
import pptIcon from "../assets/icons/pptIcon.svg";
import blankFavIcon from "../assets/icons/blankHeart.svg";

//Parent Home imgaes
import articleImg1 from "../assets/images/article1.svg";
import articleImg2 from "../assets/images/article2.svg";
import articleImg3 from "../assets/images/article3.svg";
import articleImg4 from "../assets/images/article4.svg";
import apple from "../assets/icons/apple.svg";
import music from "../assets/icons/music.svg";
import video from "../assets/icons/video.svg";
import quotes from "../assets/icons/quotes.svg";
import flag from "../assets/icons/flag.svg";
import speechTherapyIcon from "../assets/icons/speechTherapy.svg";
import dottedBorder from "../assets/icons/dottedBorder.svg";
import lockedCheckpoint from "../assets/icons/lockedCheckpoint.svg";
import physicalIcon from "../assets/icons/physical.svg";
import exerciseImg from "../assets/images/exerciseImg.svg";
import activities from "../assets/icons/activitiesIcon.svg";
import articles from "../assets/icons/articlesIcon.svg";

//Parent Progress images
import completeCheckpoint from "../assets/icons/completeCheckpoint.svg";
import lockCheckpoint from "../assets/icons/lockCheckpoint.svg";
import ongoingCheckpoint from "../assets/icons/ongoingCheckpoint.svg";
import completeCheckpointDash from "../assets/icons/completeCheckpointDash.svg";
import verticleCompleteCheckpointDash from "../assets/icons/verticleCompleteCheckpointDash.svg";
import horizontalCheckpointDash from "../assets/icons/horizontalCheckpointDash.svg";
import verticleCheckpointDash from "../assets/icons/verticleCheckpointDash.svg";

//Parent session images
import wallet from "../assets/icons/wallet.svg";
import caretCircleLeft from "../assets/icons/CaretCircleLeft.svg";
import caretCircleRight from "../assets/icons/CaretCircleRight.svg";
import infoGray from "../assets/icons/infogray.svg";
import redClock from "../assets/icons/redClock.svg";
import blueCalender from "../assets/icons/blueCalender.svg";
import warningYellow from "../assets/icons/warningYellow.svg";

//Feedback images
import disappointedFace from "../assets/icons/disappointedFace.svg";
import slightlySmilingFace from "../assets/icons/slightlySmilingFace.svg";
import smilingFacewithHeartShapeedEyes from "../assets/icons/smilingFacewithHeartShapedEyes.svg";
import smilingFacewithOpenMouth from "../assets/icons/smilingFacewithOpenMouth.svg";
import worriedFace from "../assets/icons/worriedFace.svg";
import thankyouImg from "../assets/images/thanyouImg.png";
import disappointedFaceActive from "../assets/icons/disappointedFaceActive.svg";
import slightlySmilingFaceActive from "../assets/icons/slightlySmilingActive.svg";
import smilingFacewithHeartShapeedEyesActive from "../assets/icons/smilingFaceWithHeartActive.svg";
import smilingFacewithOpenMouthActive from "../assets/icons/smilingWithOpenMouthActive.svg";
import worriedFaceActive from "../assets/icons/worriedFaceActive.svg";

//Parent Purchase Session
import iconGreen from "../assets/icons/iconGreen.svg";
import popularTag from "../assets/icons/popularTag.svg";

//Parent Explore
import exploreWorkshop from "../assets/images/exploreWorkshop.png";
import arrowCircleLeft from "../assets/icons/arrowCircleLeft.svg";
import arrowCircleRightActive from "../assets/icons/arrowCircleRightActive.svg";
import arrowCircleLeftActive from "../assets/icons/arrowCircleLeftActive.svg";
import arrowCircleRight from "../assets/icons/arrowCircleRight.svg";

import mobileNotAllowed from "../assets/images/mobileNotAllowed.svg";

export const LOGO = logo;
export const CALENDER_BLANK = calenderBlank;
export const USER_LIST = userList;
export const BOOKS = books;
export const BABY = baby;
export const PROFILE = profile;
export const HELP = help;
export const SEARCH = search;
export const NOTIFICATION = notification;
export const LAYOUT_BANNER = layoutBanner;
export const PHONE = phone;
export const GOGGLE_LOGO = googleLogo;
export const AUTH_BOTTOM_BG = authBottomBg;
export const ACTIVE_CALENDER_BLANK = calenderBlankActive;
export const ACTIVE_BOOKS = booksActive;
export const ACTIVE_USER_LIST = userListActive;
export const ACTIVE_BABY = babyActive;
export const BACK_CARET = backCaret;
export const NEXT_CARET = nextCaret;
export const ATTENDED = attendedIcon;
export const CANCELLED = cancelledIcon;
export const PARTIALLY_ATTENDED = partiallyAttendedIcon;
export const RESCHEDULED = rescheduledIcon;
export const SCHEDULED = scheduledIcon;
export const WARNING = warning;
export const NO_SHOW = noShowIcon;
export const REQUEST_SESSION = sessionRequestIcon;
export const ORANGE_WARNING = orangeWarning;
export const VERIFICATION_PEIDING = pendingVerfication;
export const UPLOAD_ICON = uploadIcon;
export const NO_USER_IMAGE = noUserImage;
export const CANCEL_TIME_SLOT = cancelTimeSlot;
export const UPDATE_STATUS = updateStatusIcon;
export const CLOSE = closeIcon;
export const INFO_ICON = infoIcon;
export const DELETE_ICON = deleteIcon;
export const ADD_PLUS_ICON = addIcon;
export const ACTIVE_SORTING_ICON = activeSorting;
export const OPEN_PATHWAY = openPath;
export const CLOSE_PATHWAY = closePath;
export const CAKE_ICON = cake;
export const PARENT_ICON = parent;
export const LOCKED_PATHWAY = lock;
export const TOP_BUTTON = top;
export const TRS = trs;
export const SLC = slc;
export const PHY = phy;
export const EW = ew;
export const NL = nl;
export const SH = sh;
export const BACK_BTN = back;
export const ACTIVE_HOME = activeHome;
export const HOME = home;
export const CHARTLINE = chartLine;
export const ACTIVE_CHARTLINE = activeChartLine;
export const SPARKLE = sparkle;
export const ACTIVE_SPARKLE = activeSparkle;
export const USER_ICON = userIcon;
export const ARTICLE1 = articleImg1;
export const ARTICLE2 = articleImg2;
export const ARTICLE3 = articleImg3;
export const ARTICLE4 = articleImg4;
export const APPLE = apple;
export const MUSIC = music;
export const VIDEO = video;
export const QUOTES = quotes;
export const FLAG = flag;
export const SPEECH_THERAPY = speechTherapyIcon;
export const DOTTED_BORDER = dottedBorder;
export const LOCKED_CHECKPOINT = lockedCheckpoint;
export const PHYSICAL_ICON = physicalIcon;
export const HOME_MOBILE = homeMobile;
export const SPARKLE_MOBILE = sparkleMobile;
export const CHARTLINE_MOBILE = chartLineMobile;
export const USERLIST_MOBILE = userListMobile;
export const CALENDER_BLANK_MOBILE = calenderBlankMobile;
export const AUTH_BOTTOM_BG_MOBILE = authBottomBgMobile;
export const HOME_ACTIVE_MOBILE = homeActiveMobile;
export const SPARKLE_ACTIVE_MOBILE = sparkleActiveMobile;
export const USERLIST_ACTIVE_MOBILE = userListActiveMobile;
export const CALENDER_BLANK_ACTIVE_MOBILE = calenderBlankActiveMobile;
export const CHARTLINE_ACTIVE_MOBILE = chartLineActiveMobile;
export const EXERCISE_IMG = exerciseImg;
export const ONGOING = ongoing;
export const ACCOMPLISHED = accomplished;
export const INACTIVE_SORTING_ICON = inactiveSorting;
export const CHECKED = checked;
export const NEXT = next;
export const GENERAL_GROUP = generalGroup;
export const SPEECH_GROUP = speechGroup;
export const BEHAVIOUR_GROUP = behaviourGroup;
export const OCCUPATIONAL_GROUP = occupationGroup;
export const PLAY = playbtn;
export const DRAG = drag;
export const PLUS = plusSign;
export const FAV = favSign;
export const COMPLETE_CHECKPOINT = completeCheckpoint;
export const LOCK_CHECKPOINT = lockCheckpoint;
export const ONGOING_CHECKPOINT = ongoingCheckpoint;
export const HORIZONTAL_CHECKPOINT_DASH = horizontalCheckpointDash;
export const VERTICLE_CHECKPOINT_DASH = verticleCheckpointDash;
export const COMPLETE_CHECKPOINT_DASH = completeCheckpointDash;
export const VERTICLE_COMPLETE_CHECKPOINT_DASH = verticleCompleteCheckpointDash;
export const VIDEO_ICON = videoIcon;
export const AUDIO_ICON = audioIcon;
export const PDF_ICON = pdfIcon;
export const DOC_ICON = docIcon;
export const IMG_ICON = imageIcon;
export const PPT_ICON = pptIcon;
export const BLANK_FAV = blankFavIcon;
export const WALLET = wallet;
export const CARET_CIRCLE_LEFT = caretCircleLeft;
export const CARET_CIRCLE_RIGHT = caretCircleRight;
export const INFO_GRAY = infoGray;
export const RED_CLOCK = redClock;
export const BLUE_CALENDER = blueCalender;
export const RIGHT_CARET_BLACK = rightCaretBlack;
export const LEFT_CARET_BLACK = leftCaretBlack;
export const DISAPPOINTED_FACE = disappointedFace;
export const SLIGHTLY_SMILING_FACE = slightlySmilingFace;
export const SMILINGWITH_HEART_SHAPED_EYES = smilingFacewithHeartShapeedEyes;
export const SMILINGWITH_OPEN_MOUTH = smilingFacewithOpenMouth;
export const WORRIED_FACE = worriedFace;
export const THANK_YOU_IMG = thankyouImg;
export const DISAPPOINTED_FACE_ACTIVE = disappointedFaceActive;
export const SLIGHTLY_SMILING_FACE_ACTIVE = slightlySmilingFaceActive;
export const SMILINGWITH_HEART_SHAPED_EYES_ACTIVE =
  smilingFacewithHeartShapeedEyesActive;
export const SMILINGWITH_OPEN_MOUTH_ACTIVE = smilingFacewithOpenMouthActive;
export const WORRIED_FACE_ACTIVE = worriedFaceActive;
export const PLUS_ICON = plusIcon;
export const CHILD_DOC = childDoc;
export const CANCEL_RED = cancelRed;
export const ICON_GREEN = iconGreen;
export const POPULAR_TAG = popularTag;
export const EXPLORE_WORKSHOP = exploreWorkshop;
export const ARROW_CIRCLE_LEFT = arrowCircleLeft;
export const ARROW_CIRCLE_RIGHT_ACTIVE = arrowCircleRightActive;
export const ARROW_CIRCLE_LEFT_ACTIVE = arrowCircleLeftActive;
export const ARROW_CIRCLE_RIGHT = arrowCircleRight;
export const ACTIVITIES = activities;
export const ARTICLES = articles;
export const WARNING_YELLOW = warningYellow;
export const MOBILE_NOT_ALLOWED = mobileNotAllowed;
export const FILE = file;
export const OPEN = open;
export const CHILD_INFO = childInfo;
export const MEDICAL_HISTORY = medicalHistory;
export const OBSERVATIONS = observations;
export const ACADEMIC_HISTORY = academicHistory;
export const GIRL = girl;
export const BOY = boy;
export const GREEN_CHECK_MARK = checkMarkGreen;
export const STAR_ICON = starIcon;
export const PLUS_ICON_BLACK = plusIconBlack;
export const PERSON_ICON = personIcon;
export const EXPERT_ICON = expertIcon;
export const DATA_ICON = dataIcon;
export const PERSON_ACTIVE_ICON = personActiveIcon;
export const EXPERT_ACTIVE_ICON = expertActiveIcon;
export const DATA_ACTIVE_ICON = dataActiveIcon;
