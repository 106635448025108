import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Avatar,
  Flex,
  GridItem,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { handleStringManupulation } from "../../../../../utils/customHooks";

import Loader from "../../../../../common/components/Loader";
import "../parentDetails.css";

const Profile = ({ loader, user }) => {
  return (
    <Box id="mainWrapper" px="16px" py="24px">
      <Text className="txnsTabText1">Profile</Text>

      {loader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <>
          <Flex className="parentProfileMainBodyContainer">
            <Avatar
              className="parentProfileAvatar"
              name={user?.fullName}
              src={user?.image}
            />
            <Flex flexDirection="column" ml="16px" justifyContent="center">
              <Text className="profileChildName">{user?.fullName}</Text>
              <Flex mt="4px">
                <Flex gap="4px" mr="8px">
                  <Text className="profileAgeGender">{user?.email}</Text>
                </Flex>
                <Text style={{ marginTop: "-5px" }}>.</Text>
                <Flex gap="4px" ml="8px">
                  <Text className="profileAgeGender">{user?.phoneNumber}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <SimpleGrid
            rowGap="16px"
            gridTemplateColumns={{ base: "1 1fr", sm: "160px 1fr" }}
            className="parentProfileMainBodyContainer2"
          >
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">ID</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">{user?.id}</Text>
            </GridItem>
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Location</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {user?.location}
              </Text>
            </GridItem>
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Language</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {handleStringManupulation(user?.languages)}
              </Text>
            </GridItem>
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">WhatsApp Number</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {user?.whatsappNumberCountryCode + user?.whatsappNumber}
              </Text>
            </GridItem>
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};

export default Profile;
