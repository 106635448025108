import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Avatar,
  Flex,
  GridItem,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { handleStringManupulation } from "../../../../../utils/customHooks";
import {
  getMyExperts,
  getParentExperts,
  toggleMainLoader,
} from "../../../../../modules/actions";
import Loader from "../../../../../common/components/Loader";
import "../parentDetails.css";

const Expert = ({ user }) => {
  const dispatch = useDispatch();
  const parentExperts = useSelector((state) => state.parentExperts);
  const { mainLoader } = useSelector((state) => state?.loader);

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getParentExperts(user.id));
  }, [user]);

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      <Text className="txnsTabText1">Experts</Text>
      <Text className="txnsTabText2">
        Information about your child’s expert
      </Text>
      {mainLoader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        parentExperts.map((expert) => {
          return (
            <>
              <Flex className="parentProfileMainBodyContainer">
                <Avatar
                  className="parentProfileAvatar"
                  name={expert?.fullName}
                  src={expert?.image}
                />
                <Flex flexDirection="column" ml="16px" justifyContent="center">
                  <Text className="profileChildName">{expert?.fullName}</Text>
                  <Flex mt="4px">
                    <Text className="profileAgeGender">
                      {handleStringManupulation(expert?.serviceTypes)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <SimpleGrid
                rowGap="16px"
                gridTemplateColumns={{ base: "1 1fr", sm: "160px 1fr" }}
                className="parentProfileMainBodyContainer2"
              >
                <GridItem colSpan="160px">
                  <Text className="servicesMainBodyTitle">Qualification</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  {expert.qualifications.map((qualification) => (
                    <Text className="servicesMainBodyTitleDesc">
                      {qualification.degree +
                        " - " +
                        qualification.fieldOfStudy}
                    </Text>
                  ))}
                </GridItem>
                <GridItem colSpan="160px">
                  <Text className="servicesMainBodyTitle">Service Type</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text className="servicesMainBodyTitleDesc">
                    {handleStringManupulation(expert?.serviceTypes)}
                  </Text>
                </GridItem>
                <GridItem colSpan="160px">
                  <Text className="servicesMainBodyTitle">Experience</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text className="servicesMainBodyTitleDesc">
                    {expert.yearsOfExperience} years
                  </Text>
                </GridItem>
                <GridItem colSpan="160px">
                  <Text className="servicesMainBodyTitle">Conditions</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text className="servicesMainBodyTitleDesc">
                    {handleStringManupulation(expert?.condition)}
                  </Text>
                </GridItem>

                <GridItem colSpan="160px">
                  <Text className="servicesMainBodyTitle">Age range</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text className="servicesMainBodyTitleDesc">
                    {expert?.startingAgeRange} to {expert?.endingAgeRange} year
                    old
                  </Text>
                </GridItem>
                <GridItem colSpan="160px">
                  <Text className="servicesMainBodyTitle">Language</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text className="servicesMainBodyTitleDesc">
                    {handleStringManupulation(expert.languages)}
                  </Text>
                </GridItem>
              </SimpleGrid>
            </>
          );
        })
      )}
    </Box>
  );
};

export default Expert;
