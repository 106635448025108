import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "chakra-ui-steps";
import moment from "moment";
import { RescheduleSessionStepper } from "./rescheduleSessionStepper";
import { rescheduleSessionService } from "../../modules/services";
import {
  toggleAPILoader,
  getParentSessions,
  toggleSessionLoader,
  getTherapistSessions,
  toggleModalOpen,
  getParentUpcomingSession,
  toggleMainLoader,
  getBalanceDetails,
  getSupportSessions,
} from "../../modules/actions";
import { Toaster } from "../../common/components/Toaster";
import "./rescheduledPopup.css";

const RescheduledPopup = ({
  isOpen,
  onClose,
  title,
  session,
  reschedulingPerson,
  type,
  reqDataThearapistSessions,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.profile);
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const [calenderStartDate, setCalenderStartDate] = useState();
  const [rescheduledBy, setRescheduledBy] = useState("Admin");
  const [data, setData] = useState({
    reason: "",
    reasonError: "",
    additionalDetails: "",
    additionalDetailsError: "",
    startDate: "",
    startDateError: "",
  });

  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const [message, setMessage] = useState("");
  const [penalty, setPenalty] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState("");
  const cancelTimeLimit = 240;
  const cancelTimeLimitExpert = 720;

  useEffect(() => {
    if (session) {
      timeComparison();
    }
  }, [session, rescheduledBy]);

  const handleCalendarChange = (event) => {
    const selectedDate = moment(event);
    setCalenderStartDate(selectedDate);
    handleFormDataChange("startDate", selectedDate, "startDateError");
  };

  const timeComparison = () => {
    const now = new Date();
    const sessionDate = new Date(
      session?.sessionDate._seconds * 1000 +
        session?.sessionDate._nanoseconds / 1000000
    );

    // starting time of the session
    let startTime = session?.startTime.split(":");
    const startingHour = parseInt(startTime[0], 10);
    const startingMinute = parseInt(startTime[1], 10);
    startTime = startingHour * 60 + startingMinute;

    // This will give the difference in dates basically
    let timeDifference = sessionDate - now;
    timeDifference /= 1000 * 60;

    // This will give the difference between session start time and cancellation time in minutes
    timeDifference += startTime;

    if (reschedulingPerson === "Parent" || rescheduledBy === "Parent") {
      if (
        timeDifference > cancelTimeLimit ||
        session?.status === "Session Request"
      ) {
        setMessage("no penalty will be charged");
        setPenalty(0);
      } else if (timeDifference < cancelTimeLimit && timeDifference > 0) {
        setMessage("you will be charged 50% of the session fees AS FINE.");
        setPenalty((session?.sessionValue / 100) * 50);
      } else if (timeDifference < 0) {
        setPenalty(session?.sessionValue);
        setMessage("you will be charged 100% of the session fees AS FINE.");
      }
    } else if (reschedulingPerson === "Expert" || rescheduledBy === "Expert") {
      if (
        timeDifference > cancelTimeLimitExpert ||
        session?.status === "Session Request"
      ) {
        setPenalty(0);
        setMessage("no Penalty will be charged");
      } else if (timeDifference < cancelTimeLimitExpert && timeDifference > 0) {
        setPenalty((session?.sessionValue / 100) * 50);
        setMessage("you will be charged 50% of the session fees AS FINE.");
      } else if (timeDifference < 0) {
        setPenalty(session?.sessionValue);
        setMessage("you will be charged 100% of the session fees AS FINE.");
      }
    } else if (reschedulingPerson === "Support" && rescheduledBy === "Admin") {
      setPenalty(0);
      setMessage("no Penalty will be charged");
    }
  };

  const handleFormDataChange = (name, value, errorName) => {
    setData({ ...data, [name]: value, [errorName]: "" });
  };

  const handleNextStep = () => {
    const { reason, additionalDetails } = data;

    if (
      activeStep === 0 ||
      (reschedulingPerson === "Support" && activeStep === 1)
    ) {
      nextStep();
    } else if (
      (reschedulingPerson === "Support" && activeStep === 2) ||
      (reschedulingPerson !== "Support" && activeStep === 1)
    ) {
      const validateReason = !reason ? "Please choose reason" : "";

      setData({
        ...data,
        reasonError: validateReason,
      });

      if (penalty === 0) {
        nextStep();
      } else if (penalty > user.currentBalance) {
        window.open("https://wa.me/+919606719826");
        dispatch(toggleModalOpen(false));
        onClose();
      } else if (!validateReason) {
        nextStep();
      }
    } else {
      const validateSlot =
        Object.keys(selectedSlot).length === 0 ? "Please choose a slot" : "";

      setData({
        ...data,
        startDateError: validateSlot,
      });

      if (Object.keys(selectedSlot).length > 0) {
        const reqData = {
          choosenSlot: selectedSlot,
          reason: reason.value,
          additionalDetails,
          sessionId: session.id,
          reschedulingPerson: reschedulingPerson,
          penalty: penalty,
        };
        dispatch(toggleAPILoader(true));
        rescheduleSessionService(reqData)
          .then((res) => {
            Toaster("Session rescheduled", "success", "top-left");
            dispatch(toggleAPILoader(false));

            if (reschedulingPerson === "Parent") {
              dispatch(
                getParentSessions(
                  { status: "upcoming" },
                  {
                    filterDate: moment().format("YYYY/MM/DD"),
                  }
                )
              );
              dispatch(toggleMainLoader(true));
              dispatch(
                getParentUpcomingSession({
                  filterDate: moment().format("YYYY/MM/DD"),
                })
              );
              dispatch(toggleSessionLoader(true));
              dispatch(getBalanceDetails());
            } else if (reschedulingPerson === "Expert") {
              dispatch(toggleSessionLoader(true));
              dispatch(getTherapistSessions(reqDataThearapistSessions));
            } else {
              dispatch(toggleSessionLoader(true));
              dispatch(getSupportSessions(reqDataThearapistSessions));
            }

            handleClosePopup();
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleAPILoader(false));
            Toaster(error.response.data.message, "error", "top-left");
          });
      }
    }
  };

  const handleClosePopup = () => {
    dispatch(toggleModalOpen(false));
    setStep(0);
    setData({
      reason: "",
      reasonError: "",
      additionalDetails: "",
      additionalDetailsError: "",
      startDate: "",
      startDateError: "",
    });
    setCalenderStartDate("");
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        mb={{ base: activeStep === 2 ? "110px" : "auto", md: "auto" }}
      >
        <RescheduleSessionStepper
          title={title}
          activeStep={activeStep}
          data={data}
          handleFormDataChange={handleFormDataChange}
          message={message}
          session={session}
          type={type}
          calenderStartDate={calenderStartDate}
          handleCalendarChange={handleCalendarChange}
          isRescheduled={
            reschedulingPerson === "Parent"
              ? penalty === 0
                ? true
                : Number(penalty) <= Number(user.currentBalance)
              : true
          }
          setSelectedSlot={setSelectedSlot}
          reschedulingPerson={reschedulingPerson}
          rescheduledBy={rescheduledBy}
          setRescheduledBy={setRescheduledBy}
        />

        <ModalFooter
          mt="16px"
          borderTop="1px solid #a6b8c4"
          justifyContent="space-between"
        >
          <Text
            className="readPolicyText"
            onClick={() =>
              window.open(
                "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Reschedule+Policy+(1).pdf"
              )
            }
          >
            Read policy
          </Text>
          <Flex>
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={() => {
                handleClosePopup();
              }}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={handleNextStep}
            >
              {/* {apiLoader ? (
                <Spinner />
              ) : activeStep === 0 || activeStep === 1 ? (
                activeStep === 1 && penalty === 0 ? (
                  "Next"
                ) : activeStep === 1 && penalty > user.currentBalance ? (
                  "Contact Support"
                ) : (
                  "Next"
                )
              ) : (
                "Confirm"
              )} */}
              {apiLoader ? (
                <Spinner />
              ) : reschedulingPerson === "Support" ? (
                activeStep === 0 || activeStep === 1 || activeStep === 2 ? (
                  activeStep === 1 && penalty === 0 ? (
                    "Next"
                  ) : activeStep === 1 && penalty > user.currentBalance ? (
                    "Contact Support"
                  ) : (
                    "Next"
                  )
                ) : (
                  "Confirm"
                )
              ) : activeStep === 0 || activeStep === 1 ? (
                activeStep === 1 && penalty === 0 ? (
                  "Next"
                ) : activeStep === 1 && penalty > user.currentBalance ? (
                  "Contact Support"
                ) : (
                  "Next"
                )
              ) : (
                "Confirm"
              )}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RescheduledPopup;
