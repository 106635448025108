import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSteps } from "chakra-ui-steps";
import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Text,
  SimpleGrid,
  GridItem,
  FormErrorMessage,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { CANCEL_RED, CLOSE } from "../../../../constant/images";
import { validateTextField } from "../../../../utils/validations";
import { Toaster } from "../../../../common/components/Toaster";
import {
  toggleAPILoader,
  toggleMainLoader,
  getUserData,
} from "../../../../modules/actions";
import { uploadChildDocsSupportService } from "../../../../modules/services";

const UploadDoc = ({ setOverlayOpen, id }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [documentData, setDocumentData] = useState({
    documentName: "",
    documentNameError: "",
    files: [],
    fileError: "",
  });

  const handleFormDataChange = (name, value, errorName) => {
    setBtnDisabled(false);
    if (name === "files") {
      setDocumentData({
        ...documentData,
        [name]: Array.from(value),
        [errorName]: "",
      });
    } else {
      setDocumentData({ ...documentData, [name]: value, [errorName]: "" });
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleOverlayCloser = () => {
    setOverlayOpen(false);
  };

  const handleFileCancel = (fileIndex) => {
    const files = documentData.files;
    files.splice(fileIndex, 1);
    setDocumentData({
      ...documentData,
      files,
    });
  };

  const handleNextStep = () => {
    const { documentName, files } = documentData;

    const validateDocumentName = !validateTextField(documentName)
      ? "Please enter valid document name"
      : "";

    const validateFiles = files.length < 1 ? "Please choose files" : "";

    const selectedFiles = files;
    const formData = new FormData();
    formData.append("name", documentName);

    selectedFiles.map((file, index) => {
      if (!isValidFileType(file)) {
        selectedFiles.splice(index, 1);
      }
      formData.append(file.name, file);
    });

    setDocumentData({
      ...documentData,
      documentNameError: validateDocumentName,
      fileError: validateFiles,
      files: selectedFiles,
    });

    if (!validateDocumentName && !validateFiles) {
      uploadChildDocsApiCall(formData);
    }
  };
  const isValidFileType = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    return allowedTypes.includes(file.type);
  };

  const uploadChildDocsApiCall = (reqData) => {
    dispatch(toggleAPILoader(true));
    uploadChildDocsSupportService(id, reqData)
      .then((res) => {
        Toaster("Child documents uploaded successfully", "success", "top-left");
        dispatch(toggleAPILoader(false));
        dispatch(toggleMainLoader(true));
        dispatch(getUserData(id));
        setOverlayOpen(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error", "top-left");
      });
  };

  return (
    <Box>
      <Box className="overlayComponentMainBox"></Box>
      <Box className="overlayComponentContentBoxParent">
        <Flex justifyContent="space-between">
          <Text className="editProfileTitle">Upload Document</Text>
          <Image
            className="overlayComponentContentBoxFlexImage"
            onClick={handleOverlayCloser}
            src={CLOSE}
          />
        </Flex>
        <Flex className="overlayComponentContentBoxFlexParent"></Flex>
        <Box className="overlayComponentContentBoxChildren">
          <SimpleGrid gap="16px" p="16px">
            <GridItem>
              <FormControl isInvalid={documentData?.documentNameError}>
                <FormLabel className="personalFormLabel">
                  Document name
                </FormLabel>
                <InputGroup>
                  <Input
                    onChange={(e) =>
                      handleFormDataChange(
                        e.target.name,
                        e.target.value,
                        "documentNameError"
                      )
                    }
                    name="documentName"
                    value={documentData?.documentName}
                    placeholder="Enter your document name"
                    maxLength={150}
                    border="1px solid #A6B8C4"
                    className="myProfileFormInput"
                  />
                </InputGroup>
                {documentData?.documentNameError && (
                  <FormErrorMessage className="personalErrorMessage">
                    {documentData?.documentNameError}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={documentData?.fileError}>
                <FormLabel className="personalFormLabel">
                  Upload Document
                </FormLabel>
                <Button className="uploadFileBtn" onClick={handleButtonClick}>
                  Choose file to upload
                </Button>
                <InputGroup>
                  <Input
                    type="file"
                    name="files"
                    multiple
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                    onChange={(e) =>
                      handleFormDataChange(
                        e.target.name,
                        e.target.files,
                        "fileError"
                      )
                    }
                  />
                </InputGroup>
                {documentData?.fileError && (
                  <FormErrorMessage className="personalErrorMessage">
                    {documentData?.fileError}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            {documentData.files.map((file, index) => (
              <Flex key={index} justifyContent="space-between">
                <Text className="fileNames">{file.name}</Text>
                <Image
                  src={CANCEL_RED}
                  height="20px"
                  width="20px"
                  cursor="pointer"
                  onClick={() => handleFileCancel(index)}
                />
              </Flex>
            ))}
          </SimpleGrid>
        </Box>
        <Flex
          className="overlayComponentContentBoxBtnsFlex"
          justifyContent="flex-end !important"
        >
          <Button
            className="overlayComponentContentBoxBtn2"
            color={
              apiLoader ||
              btnDisabled ||
              (documentData?.documentNameError && documentData?.fileError)
                ? "#91A2AE"
                : "white"
            }
            bg={
              apiLoader ||
              btnDisabled ||
              (documentData?.documentNameError && documentData?.fileError)
                ? "#F3F8FB"
                : "#4299E1"
            }
            onClick={handleNextStep}
            isDisabled={
              btnDisabled ||
              (documentData?.documentNameError && documentData?.fileError)
            }
            _disabled={{
              opacity: "unset",
              cursor: "not-allowed",
            }}
          >
            {apiLoader ? <Spinner /> : <Text>Upload</Text>}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default UploadDoc;
