// UsersActions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_USER_PROFILE = "GET_USER_PROFILE";

export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";

export const SHOW_OR_HIDE_MAIN_LOADER = "SHOW_OR_HIDE_MAIN_LOADER";

export const SHOW_OR_HIDE_API_LOADER = "SHOW_OR_HIDE_API_LOADER";

export const SHOW_OR_HIDE_SESSION_LOADER = "SHOW_OR_HIDE_SESSION_LOADER";

export const SHOW_OR_HIDE_FEEDBACK_LOADER = "SHOW_OR_HIDE_FEEDBACK_LOADER";

export const CHECK_MODAL_OPEN = "CHECK_MODAL_OPEN";

export const SAVE_USER_PROFILE_DETAILS = "SAVE_USER_PROFILE_DETAILS";

export const SAVE_USER_PROFILE_IMAGE_DETAILS =
  "SAVE_USER_PROFILE_IMAGE_DETAILS";

export const GET_EXPERT_TRANSACTIONS = "GET_EXPERT_TRANSACTIONS";

export const SAVE_EXPERT_TRANSACTIONS = "SAVE_EXPERT_TRANSACTIONS";

export const GET_EXPERT_LEAVES = "GET_EXPERT_LEAVES";

export const SAVE_EXPERT_DATA = "SAVE_EXPERT_DATA";

export const GET_PARENT_TOKEN = "GET_PARENT_TOKEN";

export const SAVE_PARENT_TOKEN = "SAVE_PARENT_TOKEN";

export const APPLY_EXPERT_LEAVES = "APPLY_EXPERT_LEAVES";

//Schedule page Actions
export const GET_THERAPIST_SESSIONS = "GET_THERAPIST_SESSIONS";

export const SAVE_THARAPIST_SESSIONS = "SAVE_THARAPIST_SESSIONS";

export const GET_SUPPORT_SESSIONS = "GET_SUPPORT_SESSIONS";

export const SAVE_SUPPORT_SESSIONS = "SAVE_SUPPORT_SESSIONS";

// Pathway Actions
export const GET_CHILD_PATHWAY = "GET_CHILD_PATHWAY";

export const SAVE_CHILD_PATHWAY = "SAVE_CHILD_PATHWAY";

export const GET_OUTCOME_DETAILS = "GET_OUTCOME_DETAILS";

export const SAVE_OUTCOME_DETAILS = "SAVE_OUTCOME_DETAILS";

export const ADD_PARENT_OUTCOME_STATUS = "ADD_PARENT_OUTCOME_STATUS";

export const DOWNLOAD_CHECKPOINT = "DOWNLOAD_CHECKPOINT";

export const SAVE_DOWNLOAD_CHECKPOINT = "SAVE_DOWNLOAD_CHECKPOINT";

// MY students page Actions
export const GET_ALL_STUDENTS = "GET_ALL_STUDENTS";

export const SAVE_ALL_STUDENTS_DATA = "SAVE_ALL_STUDENTS_DATA";

export const GET_DOMAINS_GOAL_DATA = "GET_DOMAINS_GOAL_DATA";

export const SAVE_DOMAINS_GOAL_DATA = "SAVE_DOMAINS_GOAL_DATA";

export const GET_DOMAIN_SUB_GOALS_DATA = "GET_DOMAIN_SUB_GOALS_DATA";

export const SAVE_DOMAIN_SUB_GOALS_DATA = "SAVE_DOMAIN_SUB_GOALS_DATA";

export const GET_PARENT_SESSION_HISTORY = "GET_PARENT_SESSION_HISTORY";

export const SAVE_PARENT_SESSION_HISTORY = "SAVE_PARENT_SESSION_HISTORY";

export const GET_PARENT_JOURNAL = "GET_PARENT_JOURNAL";

export const SAVE_PARENT_JOURNAL = "SAVE_PARENT_JOURNAL";

export const GET_DOMAIN_DATA = "GET_DOMAIN_DATA";

export const SAVE_DOMAIN_DATA = "SAVE_DOMAIN_DATA";

export const GET_STUDENTS_NOTES = "GET_STUDENTS_NOTES";

export const SAVE_STUDENTS_NOTES = "SAVE_STUDENTS_NOTES";

export const WRITE_STUDENTS_NOTES = "WRITE_STUDENTS_NOTES";

export const DELETE_STUDENTS_NOTES = "DELETE_STUDENTS_NOTES";

export const UPDATE_STUDENTS_NOTES = "UPDATE_STUDENTS_NOTES";

export const GET_CHILDS_DOMAIN_DATA = "GET_CHILDS_DOMAIN_DATA";

export const SAVE_CHILDS_DOMAIN_DATA = "SAVE_CHILDS_DOMAIN_DATA";

//Reources actions

export const GET_DOMAIN_RESOURCES = "GET_DOMAIN_RESOURCES";

export const SAVE_DOMAIN_RESOURCES = "SAVE_DOMAIN_RESOURCES";

export const GET_FAVOURITE_RESOURCES = "GET_FAVOURITE_RESOURCES";

export const SAVE_FAVOURITE_RESOURCES = "SAVE_FAVOURITE_RESOURCES";

export const GET_MY_EXPERTS = "GET_MY_EXPERTS";

export const SAVE_MY_EXPERTS = "SAVE_MY_EXPERTS";

//Session actions

export const GET_PARENT_SESSIONS = "GET_PARENT_SESSIONS";

export const SAVE_PARENT_SESSIONS = "SAVE_PARENT_SESSIONS";

export const GET_OPEN_SLOTS = "GET_OPEN_SLOTS";

export const SAVE_OPEN_SLOTS = "SAVE_OPEN_SLOTS";

export const GET_RESCHEDULE_OPEN_SLOTS = "GET_RESCHEDULE_OPEN_SLOTS";

export const SAVE_RESCHEDULE_OPEN_SLOTS = "SAVE_RESCHEDULE_OPEN_SLOTS";

export const GET_USER_DATA = "GET_USER_DATA";

export const SAVE_USER_DATA = "SAVE_USER_DATA";

export const GET_LAST_ATTENDED_SESSION = "GET_LAST_ATTENDED_SESSION";

export const SAVE_LAST_ATTENDED_SESSION = "SAVE_LAST_ATTENDED_SESSION";

export const GET_SESSION_BY_ID = "GET_SESSION_BY_ID";

export const SAVE_SESSION_BY_ID = "SAVE_SESSION_BY_ID";

export const START_MEETING = "START_MEETING";

export const SAVE_START_MEETING = "SAVE_START_MEETING";

export const GET_PURCHASE_PLANS = "GET_PURCHASE_PLANS";

export const SAVE_PURCHASE_PLANS = "SAVE_PURCHASE_PLANS";

export const CREATE_ORDER = "CREATE_ORDER";

export const SAVE_CREATE_ORDER = "SAVE_CREATE_ORDER";

export const GET_PARENT_UPCOMING_SESSION = "GET_PARENT_UPCOMING_SESSION";

export const SAVE_PARENT_UPCOMING_SESSION = "SAVE_PARENT_UPCOMING_SESSION";

export const GET_JOIN_CONSULATATION = "GET_JOIN_CONSULATATION";

export const SAVE_JOIN_CONSULATATION = "SAVE_JOIN_CONSULATATION";

//Explore Actions
export const GET_ACTIVITY_LISTING = "GET_ACTIVITY_LISTING";

export const SAVE_ACTIVITY_LISTING = "SAVE_ACTIVITY_LISTING";

export const GET_VIDEO_LISTING = "GET_VIDEO_LISTING";

export const SAVE_VIDEO_LISTING = "SAVE_VIDEO_LISTING";

export const GET_PARENTING_TIPS = "GET_PARENTING_TIPS";

export const SAVE_PARENTING_TIPS = "SAVE_PARENTING_TIPS";

export const GET_LIVE_BOOKING = "GET_LIVE_BOOKING";

export const SAVE_LIVE_BOOKING = "SAVE_LIVE_BOOKING";

export const GET_POPULAR_ARTICLE = "GET_POPULAR_ARTICLE";

export const SAVE_POPULAR_ARTICLE = "SAVE_POPULAR_ARTICLE";

export const GET_BALANCE_DETAILS = "GET_BALANCE_DETAILS";

export const SAVE_BALANCE_DETAILS = "SAVE_BALANCE_DETAILS";

//update ACR
export const GET_CONSULTATION_BY_ID = "GET_CONSULTATION_BY_ID";

export const SAVE_CONSULTATION_BY_ID = "SAVE_CONSULTATION_BY_ID";

export const GET_CONSULTATION_REPORT = "GET_CONSULTATION_REPORT";

export const SAVE_CONSULTATION_REPORT = "SAVE_CONSULTATION_REPORT";

export const GET_ALL_EXPERTS = "GET_ALL_EXPERTS";

export const SAVE_ALL_EXPERTS = "SAVE_ALL_EXPERTS";

export const GET_EXPERTS_PARENTS = "GET_EXPERTS_PARENTS";

export const SAVE_EXPERTS_PARENTS = "SAVE_EXPERTS_PARENTS";

export const GET_EXPERTS_LEAVES_BY_ID = "GET_EXPERTS_LEAVES_BY_ID";

export const SAVE_EXPERTS_LEAVES_BY_ID = "SAVE_EXPERTS_LEAVES_BY_ID";

export const GET_ALL_PARENTS = "GET_ALL_PARENTS";

export const SAVE_ALL_PARENTS = "SAVE_ALL_PARENTS";

export const GET_PARENT_EXPERTS = "GET_PARENT_EXPERTS";

export const SAVE_PARENT_EXPERTS = "SAVE_PARENT_EXPERTS ";
