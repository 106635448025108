import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
  Button,
  Grid,
  Text,
  Flex,
  Box,
  Divider,
  AbsoluteCenter,
  InputGroup,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "chakra-react-select";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  BACK_CARET,
  NEXT_CARET,
  workingDaysOptions,
} from "../../../../constant";
import {
  toggleAPILoader,
  getRescheduleOpenSlots,
  getExpertLeavesById,
  toggleMainLoader,
} from "../../../../modules/actions";
import { useState, useEffect } from "react";
import Loader from "../../../../common/components/Loader";
import { getDate } from "../../../../utils/customMoment";

const SlotDetails = ({
  data,
  calenderStartDate,
  handleCalendarChange,
  setSelectedSlot,
  handleFormDataChange,
}) => {
  const dispatch = useDispatch();
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const expertLeaves = useSelector((state) => state?.expertLeavesById);
  const rescheduleOpenSlots = useSelector(
    (state) => state?.rescheduleOpenSlots
  );
  const [showSlot, setShowSlot] = useState(false);

  const renderDayTileClass = ({ date }) => {
    // Check if the date should be excluded
    const hasAppliedLeave = expertLeaves?.some((leave) => {
      const currentDate = moment(date);
      const leaveStartDate = moment
        .unix(leave.leaveStartDateTime._seconds)
        .startOf("d");
      const leaveEndDate = moment
        .unix(leave.leaveEndDateTime._seconds)
        .endOf("d");

      return currentDate.isBetween(leaveStartDate, leaveEndDate, null, "[]");
    });

    // Apply custom styling for leaves
    return hasAppliedLeave ? "markOnLeave disabled" : "";
  };

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getExpertLeavesById(data.expert.value));
  }, [data.expert]);

  useEffect(() => {
    rescheduleOpenSlots.forEach((slot) => {
      //inserting an additional field isSelected for select-deselect ui
      slot.openSlots.forEach(function (element) {
        element.isSelected = false;
      });
    });
  }, [rescheduleOpenSlots, data?.startTime]);

  useEffect(() => {
    setShowSlot(false);
  }, [calenderStartDate]);

  const handleAvailableSlots = () => {
    if (calenderStartDate) {
      const reqData = {
        expert: data.expert.value,
        startDate: calenderStartDate.format("YYYY/MM/DD"),
        reschedulingPerson: "Support",
      };
      dispatch(toggleAPILoader(true));
      dispatch(getRescheduleOpenSlots(reqData));
      setShowSlot(true);
    }
  };

  const handleSlotSelection = (date, slot) => {
    let choosenSlot = {};
    rescheduleOpenSlots.map((dates) => {
      if (dates.date === date) {
        dates.openSlots.map((slotItem) => {
          if (slotItem.label === slot.label) {
            slotItem.isSelected = !slotItem.isSelected;
            if (slotItem.isSelected) {
              choosenSlot = {
                date: dates.date,
                slotStartTime: slot.value,
                slotDuration:
                  data.sessionType === "Full session paid" ? 60 : 30,
                label: slot.label,
              };
            }
          } else {
            slotItem.isSelected = false;
          }
        });
      }
    });
    setSelectedSlot(choosenSlot);
    handleFormDataChange("startTimeChange", "", "startTimeError");
  };

  return (
    <SimpleGrid gap="16px">
      <GridItem>
        <FormControl isInvalid={data?.startDateError}>
          <FormLabel className="personalFormLabel">
            Select your preferred date
          </FormLabel>
          <Calendar
            name="startDate"
            prevLabel={<Image src={BACK_CARET} />}
            nextLabel={<Image src={NEXT_CARET} />}
            calendarType="US"
            prev2Label={null}
            next2Label={null}
            view="month"
            onChange={handleCalendarChange}
            value={calenderStartDate}
            tileClassName={renderDayTileClass}
            minDate={moment().toDate()}
            maxDate={
              data.parent.parentDetails.role === "parent" &&
              data.parent.parentDetails?.gracePeriod
                ? getDate(data.parent.parentDetails?.gracePeriod)
                : ""
            }
          />
          {data?.startDateError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.startDateError}
            </FormErrorMessage>
          )}
          <Flex mt="8px" gap="8px" align="center">
            <Box className="pathwayHandlerOverlayUpcomingLeavesAboveBox"></Box>
            <Text className="pathwayHandlerOverlayUpcomingLeaves">
              Upcoming leaves
            </Text>
          </Flex>
        </FormControl>
      </GridItem>

      {calenderStartDate && (
        <GridItem>
          {/* <FormLabel className="personalFormLabel">Available slots</FormLabel> */}
          <Button
            bg="#4299E1"
            color="#FFFFFF"
            mr="8px"
            border="1px solid #a6b8c4"
            onClick={() => handleAvailableSlots()}
            className="modalButtons2"
            disabled={!calenderStartDate}
          >
            Get available slots
          </Button>
          {apiLoader ? (
            <Loader />
          ) : rescheduleOpenSlots[0]?.openSlots.length > 0 && showSlot ? (
            <>
              <FormLabel className="personalFormLabel" mt="20px">
                Select available slots
              </FormLabel>
              <Grid
                templateColumns="repeat(12, 1fr)"
                gap={4}
                mb="16px"
                pb="16px"
                mt="10px"
              >
                {rescheduleOpenSlots[0]?.openSlots.length > 0 &&
                  rescheduleOpenSlots[0]?.openSlots.map((slot) => (
                    <GridItem
                      className="slotsDiv"
                      colSpan={4}
                      bg={slot.isSelected ? "#E9F6FF" : "#ffffff"}
                      onClick={() =>
                        handleSlotSelection(rescheduleOpenSlots[0].date, slot)
                      }
                    >
                      {slot.label}
                    </GridItem>
                  ))}
              </Grid>
            </>
          ) : (
            <GridItem className="personalFormLabel" mt="20px">
              {showSlot && !apiLoader && "No slots available on this day "}
            </GridItem>
          )}
          <Box position="relative" className="orText">
            <Divider border="1px solid #A6B8C4" />
            <AbsoluteCenter bg="white" px="4">
              or
            </AbsoluteCenter>
          </Box>
          <FormControl>
            <FormLabel className="leavesApplierFlexFormLabel">
              Enter Manually
            </FormLabel>
            <Flex gap="16px" justifyContent="center" alignItems="center">
              <Box width="100%">
                <InputGroup
                  sx={{
                    "& div": {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                  }}
                >
                  <Input
                    className="leavesTabSelect"
                    name="startTime"
                    textColor="black !important"
                    type="time"
                    onChange={(e) =>
                      handleFormDataChange(
                        "startTime",
                        e.target.value,
                        "startTimeError"
                      )
                    }
                    value={data?.startTime}
                    border="1px solid #A6B8C4"
                    locale="sv-sv"
                    placeholder="From"
                    cursor="text"
                  />
                </InputGroup>
                {data?.startTimeError && (
                  <div className="personalErrorMessage">
                    {data?.startTimeError}
                  </div>
                )}
              </Box>
              <Box className="orText">to</Box>
              <Box width="100%">
                <InputGroup
                  sx={{
                    "& div": {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                  }}
                >
                  <Input
                    className="leavesTabSelect"
                    name="endTime"
                    textColor="black !important"
                    type="time"
                    onChange={(e) =>
                      handleFormDataChange(
                        "endTime",
                        e.target.value,
                        "endTimeError"
                      )
                    }
                    value={data?.endTime}
                    border="1px solid #A6B8C4"
                    locale="sv-sv"
                    placeholder="From"
                    cursor="text"
                    isDisabled
                  />
                </InputGroup>
                {data?.endTimeError && (
                  <div className="personalErrorMessage">
                    {data?.endTimeError}
                  </div>
                )}
              </Box>
            </Flex>
          </FormControl>
          <FormControl
            textAlign="left"
            isInvalid={data?.isMultipleError}
            mt="20px"
          >
            <Checkbox
              name="isMultiple"
              isChecked={data?.isMultiple}
              className="personalFormLabel"
              onChange={(e) =>
                handleFormDataChange(
                  e.target.name,
                  e.target.checked,
                  "isMultipleError"
                )
              }
            >
              Book Multiple Sessions
            </Checkbox>
          </FormControl>

          {data?.isMultiple && (
            <>
              <FormControl>
                <FormLabel className="personalFormLabel">
                  Number of Weeks
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    onChange={(e) =>
                      handleFormDataChange(
                        e.target.name,
                        e.target.value,
                        "noOfWeeksError"
                      )
                    }
                    name="noOfWeeks"
                    value={data?.noOfWeeks}
                    placeholder="Enter no of weeks"
                    maxLength={30}
                    border="1px solid #A6B8C4"
                    className="myProfileFormInput"
                  />
                </InputGroup>
                {data?.noOfWeeksError && (
                  <Box className="personalErrorMessage">
                    {data?.noOfWeeksError}
                  </Box>
                )}
              </FormControl>
              <FormControl isInvalid={data?.daysError} mt="20px">
                <FormLabel className="personalFormLabel">Select Days</FormLabel>
                <Select
                  isMulti
                  onChange={(e) => handleFormDataChange("days", e, "daysError")}
                  name="expert"
                  value={data?.days}
                  selectedOptionStyle="check"
                  placeholder="Select days"
                  className="profileFormSelect"
                  hideSelectedOptions={false}
                  selectedOptionColorScheme="green"
                  options={workingDaysOptions}
                  isSearchable={true}
                />
                {data?.daysError && (
                  <FormErrorMessage className="personalErrorMessage">
                    {data?.daysError}
                  </FormErrorMessage>
                )}
              </FormControl>
            </>
          )}
        </GridItem>
      )}
    </SimpleGrid>
  );
};
export default SlotDetails;
