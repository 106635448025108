import { useState } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useSteps } from "chakra-ui-steps";

import { ReportNoShowStepper } from "./reportNoShowStepper";
import { setNoShowConsultationService } from "../../modules/services";
import {
  toggleAPILoader,
  toggleModalOpen,
  toggleSessionLoader,
  getSupportSessions,
} from "../../modules/actions";
import { Toaster } from "../../common/components/Toaster";
import "./reportNoShowPopup.css";

const ReportNoShowPopup = ({
  isOpen,
  onClose,
  session,
  reqDataThearapistSessions,
}) => {
  const dispatch = useDispatch();
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);

  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const [noShowBy, setNoShowBy] = useState("Parent");
  const [expertWait, setExpertWait] = useState("Yes");

  const handleNextStep = () => {
    if (activeStep === 0 || (noShowBy === "Parent" && activeStep === 1)) {
      nextStep();
    } else {
      if (noShowBy && expertWait) {
        const reqData = {
          noShowBy: noShowBy,
          expertWait: expertWait,
        };
        dispatch(toggleAPILoader(true));
        setNoShowConsultationService(session.id, reqData)
          .then((res) => {
            Toaster("Consultation No Show reported", "success", "top-left");
            dispatch(toggleAPILoader(false));
            dispatch(toggleModalOpen(false));
            dispatch(toggleSessionLoader(true));
            dispatch(getSupportSessions(reqDataThearapistSessions));
            handleClosePopup();
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleAPILoader(false));
            Toaster(error.response.data.message, "error", "top-left");
          });
      }
    }
  };
  const handleClosePopup = () => {
    dispatch(toggleModalOpen(false));
    setStep(0);
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        <ReportNoShowStepper
          activeStep={activeStep}
          session={session}
          noShowBy={noShowBy}
          setNoShowBy={setNoShowBy}
          setExpertWait={setExpertWait}
          expertWait={expertWait}
        />

        <ModalFooter
          mt="16px"
          borderTop="1px solid #a6b8c4"
          // justifyContent="space-between"
        >
          {/* <Text
            className="readPolicyText"
            onClick={() =>
              window.open(
                cancellingPerson === "Parent"
                  ? "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Session+Cancellations+Policy+by+Client+(1).pdf"
                  : "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Session+Cancellations+Policy+by+Expert+(1).pdf"
              )
            }
          >
            Read policy
          </Text> */}

          <Flex>
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={() => {
                handleClosePopup();
              }}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={handleNextStep}
            >
              {apiLoader ? (
                <Spinner />
              ) : activeStep === 0 ||
                (noShowBy === "Parent" && activeStep === 1) ? (
                "Next"
              ) : (
                "Confirm"
              )}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportNoShowPopup;
