import {
  SimpleGrid,
  ModalBody,
  ModalHeader,
  Text,
  Flex,
} from "@chakra-ui/react";
import { getDatewithDay } from "../../../utils/customMoment";

const NoShowDetails = ({ session, noShowBy, expertWait }) => {
  console.log(noShowBy, expertWait);
  return (
    <SimpleGrid gap="16px" m="16px">
      <ModalHeader className="popupHeader">
        You are about to report a parent no-show for the following consultation:
      </ModalHeader>
      <ModalBody className="popupBody" mt="-20px">
        <Text className="sessionDetailsDate" mb="20px">
          {session.sessionParentName + "-" + session.sessionStudentName} /{" "}
          {session.sessionExpertName}
        </Text>

        <Text className="sessionDetailsDate" mb="20px">
          {session.serviceType}
        </Text>

        <Flex mb="20px">
          <Text className="sessionDetailsDate">
            {getDatewithDay(session?.sessionDate)}
          </Text>
          <Text style={{ margin: "-5px 8px" }}>.</Text>
          <Text className="sessionDetailsDate">
            {session?.startTime + " - " + session?.endTime}
          </Text>
        </Flex>

        <Flex mb="10px">
          <Text className="sessionDetailsDate" color="#BA1A1A">
            {"No-show By " + noShowBy}{" "}
            {noShowBy === "Parent" && expertWait === "Yes"
              ? "(expert paid)"
              : expertWait === "No"
              ? "(expert not paid)"
              : ""}
          </Text>
        </Flex>
      </ModalBody>
    </SimpleGrid>
  );
};
export default NoShowDetails;
