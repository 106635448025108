import {
  ModalBody,
  ModalHeader,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

const PopUpDetails = ({ setExpertWait, expertWait }) => {
  return (
    <>
      <ModalHeader className="popupHeader">
        Did the expert wait for the parent for more than 5 minutes?
      </ModalHeader>
      <ModalBody className="popupBody">
        <RadioGroup onChange={setExpertWait} value={expertWait}>
          <Stack direction="row">
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Stack>
        </RadioGroup>
      </ModalBody>
    </>
  );
};
export default PopUpDetails;
