import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Avatar,
  Flex,
  GridItem,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { handleStringManupulation } from "../../../../../utils/customHooks";

import Loader from "../../../../../common/components/Loader";
import "../parentDetails.css";

const Balance = ({ loader, user }) => {
  return (
    <Box id="mainWrapper" px="16px" py="24px">
      <Text className="txnsTabText1">Balance</Text>

      {loader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <>
          <SimpleGrid
            rowGap="16px"
            gridTemplateColumns={{ base: "1 1fr", sm: "160px 1fr" }}
            className="parentProfileMainBodyContainer2"
            mt="24px"
          >
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Current Balance</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {user?.currentBalance}
              </Text>
            </GridItem>
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};
export default Balance;
