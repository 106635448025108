import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { Toaster } from "../../common/components/Toaster";

export function* getTherapistSessionsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getTherapistSessionService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveTherapistSessions(response.data.data));
      yield put(actionCreators.toggleSessionLoader(false));
    } else {
      yield put(actionCreators.toggleSessionLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleSessionLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getParentSessionsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getParentSessionsService,
      action?.status.status.toLowerCase(),
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveParentSessions(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error?.response?.data?.message, "error");
  }
}

export function* getOpenSlotsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getOpenSlotsService,
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveOpenSlots(response.data.data));
      yield put(actionCreators.toggleAPILoader(false));
      action.func();
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error", "top-left");
    }
  } catch (error) {
    console.log(error.response);
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error", "top-left");
  }
}

export function* getRescheduleOpenSlotsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getRescheduleOpenSlotsService,
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveRescheduleOpenSlots(response.data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getLastAttendedSessionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getLastAttendedSessionService,
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.saveLastAttendedSession(
          response.data.data.length > 0 ? response.data.data[0] : []
        )
      );
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getSessionByIdSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getSessionByIdService,
      action?.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveSessionById(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* startMeetingSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.startMeetingService,
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveStartMeeting(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getParentUpcomingSessionSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getParentUpcomingSessionService,
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveParentUpcomingSession(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* joinConsulatationSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.joinConsulatationService,
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveJoinConsulatation(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getConsultationByIdSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getConsulatationByIdService,
      action?.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveConsultationById(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getConsultationReportSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getConsulatationReportService,
      action?.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.saveConsultationReport(
          response.data?.data.length > 0 ? response.data?.data[0] : []
        )
      );
      yield put(actionCreators.toggleSessionLoader(false));
    } else {
      yield put(actionCreators.toggleSessionLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleSessionLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getSupportSessionsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getSupportSessionService,
      action.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveSupportSessions(response.data.data));
      yield put(actionCreators.toggleSessionLoader(false));
    } else {
      yield put(actionCreators.toggleSessionLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error, "error.response");
    yield put(actionCreators.toggleSessionLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getAllExpertsSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getAllExpertsService);

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveAllExperts(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}
export function* getParentsByExpertIdSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getParentsByExpertService,
      action?.data
    );

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveExpertsParents(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error);
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}
