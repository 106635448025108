import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input,
} from "@chakra-ui/react";

const CompensationDetails = ({ data, handleFormDataChange, session }) => {
  return (
    <SimpleGrid gap="16px" m="16px">
      <GridItem>
        <FormLabel className="personalFormLabel">
          {session?.status === "Ongoing"
            ? "EXPERT NOT YET COMPENSATED"
            : session?.status === "Attended"
            ? `EXPERT COMPENSATED (+${session.sessionValue})`
            : ""}
        </FormLabel>
        <FormControl isInvalid={data?.compensationToExpertError}>
          <FormLabel className="personalFormLabel">
            Compensation to expert
          </FormLabel>
          <InputGroup>
            <Input
              type="number"
              onChange={(e) =>
                handleFormDataChange(
                  e.target.name,
                  e.target.value,
                  "compensationToExpertError"
                )
              }
              name="compensationToExpert"
              value={data?.compensationToExpert}
              placeholder="Type here..."
              maxLength={250}
              border="1px solid #A6B8C4"
              className="myProfileFormInput"
            />
          </InputGroup>

          {data?.compensationToExpertError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.compensationToExpertError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isInvalid={data?.compensationToParentError}>
          <FormLabel className="personalFormLabel">
            Compensation to parent
          </FormLabel>
          <InputGroup>
            <Input
              type="number"
              onChange={(e) =>
                handleFormDataChange(
                  e.target.name,
                  e.target.value,
                  "compensationToParentError"
                )
              }
              name="compensationToParent"
              value={data?.compensationToParent}
              placeholder="Type here..."
              maxLength={250}
              border="1px solid #A6B8C4"
              className="myProfileFormInput"
            />
          </InputGroup>
          {data?.compensationToParentError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.compensationToParentError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};
export default CompensationDetails;
